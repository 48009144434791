import React, { useRef, useState } from "react";
import * as Yup from "yup";
import {
  Box,
  createStyles,
  InputAdornment,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import OneColumnForm from "../../forms/one-column-form/OneColumnForm";

import PersonIcon from "@material-ui/icons/Person";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import CustomTextField from "../../custom-inputs/CustomTextField";
import { FinalityInfoCard } from "@portal-do-titular/ui-components";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      maxWidth: "400px",
      color: theme.palette.grey[900],
    },
    finality: {
      width: "95%",
    },
    checkboxTerm: {
      marginTop: "3px",
    },
    finalityContainer: {
      display: "flex",
      minWidth: "220px",
    },
    finalityTerms: {
      marginLeft: "5px",
    },
    finalityInfo: {
      background: "none",
      border: "none",
    },
  })
);

export interface CreateAccountDTO {
  name: string;
  email: string;
  password: string;
}

export interface CreateAccountFormProps {
  onSubmit: (params: CreateAccountDTO) => void;
}

export function CreateAccountForm({ onSubmit }: CreateAccountFormProps) {
  const classes = useStyles();
  const formRef = useRef(null);
  const [showFinalityDescription, setShowFinalityDescription] = useState(false);

  const submitHandler = async (data: CreateAccountDTO) => {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required("Nome é obrigatorio"),
        email: Yup.string()
          .email("Necessita um email valido")
          .required("Email é obrigatorio"),
        password: Yup.string()
          .min(6, "Necessita ao menos 6 caracteres")
          .required(),
        passwordTwo: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Senhas não são iguais"
        ),
      });

      await schema.validate(data, { abortEarly: false });
      onSubmit(data);
    } catch (err) {
      const validationErros = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErros[error.path] = error.message;
        });
        formRef.current.setErrors(validationErros);
      }
    }
  };

  return (
    <>
      <OneColumnForm
        button={{ label: "Criar", onSubmit: submitHandler }}
        formRef={formRef}
      >
        <CustomTextField
          fullWidth
          className={classes.input}
          required
          name="name"
          variant="outlined"
          placeholder="Nome"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />
        <CustomTextField
          fullWidth
          className={classes.input}
          required
          name="email"
          variant="outlined"
          placeholder="E-mail"
          InputProps={{
            type: "email",
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <CustomTextField
          fullWidth
          className={classes.input}
          required
          name="password"
          variant="outlined"
          placeholder="Senha"
          InputProps={{
            type: "password",
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
        <CustomTextField
          fullWidth
          className={classes.input}
          required
          name="passwordTwo"
          variant="outlined"
          placeholder="Confirmar senha"
          InputProps={{
            type: "password",
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box className={classes.finality}>
          <Typography variant="subtitle1" color="primary">
            Finalidade de Autorização
          </Typography>
          <Box className={classes.finalityContainer}>
            <input
              required
              type="checkbox"
              name="acceptTerms"
              className={classes.checkboxTerm}
            />
            <Typography variant="caption" className={classes.finalityTerms}>
              Concorda com a{" "}
              <button type="button" className={classes.finalityInfo}>
                <strong>
                  <u onClick={() => setShowFinalityDescription(true)}>
                    finalidade de autorização
                  </u>
                </strong>
              </button>{" "}
              de dados para o cadastro do portal?
            </Typography>
          </Box>
        </Box>
      </OneColumnForm>
      <FinalityInfoCard
        open={showFinalityDescription}
        onClose={() => setShowFinalityDescription(false)}
      ></FinalityInfoCard>
    </>
  );
}

export default CreateAccountForm;
