import {
  Container,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import CreateAccountForm, {
  CreateAccountDTO,
} from "../forms/create-account-form/CreateAccountForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "2%",
    },
    title: {
      padding: "2%",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.5rem",
        paddingTop: "30%",
      },
    },
  })
);

/* eslint-disable-next-line */
export interface CreateYourLoginProps {
  onSubmit: (param: CreateAccountDTO) => void;
}

export function CreateYourLogin({ onSubmit }: CreateYourLoginProps) {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Typography
        className={classes.title}
        id="create-your-login-title"
        variant="h5"
      >
        Crie seu Login
      </Typography>
      <CreateAccountForm onSubmit={onSubmit} />
    </Container>
  );
}

export default CreateYourLogin;
