import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useStyles } from "../../forms/menu-column-form/MenuColumnForm";

import "./MenuTitle.css";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    secondaryColor: {
      color: theme.palette.secondary.main,
    },
  })
);

function MenuTitle({ title }) {
  const classes = useStyles();
  return <div className={`titles-menu ${classes.secondaryColor}`}>{title}</div>;
}

export { MenuTitle };
