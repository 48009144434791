import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import { TextField, TextFieldProps, withStyles } from "@material-ui/core";
import classes from "*.module.sass";

const CssTextField = withStyles({
  root: {
    color: "#1e1e1e",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "40px",
      },
    },
  },
})(TextField);

export function CustomTextField({ name, InputProps, ...rest }: TextFieldProps) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <CssTextField
      InputProps={{
        ...InputProps,
        style: { ...InputProps?.style, color: "#1e1e1e" },
      }}
      margin="dense"
      error={!!error}
      helperText={error}
      inputRef={inputRef}
      defaultValue={defaultValue}
      {...rest}
    />
  );
}
export default CustomTextField;
