import React, { Dispatch, SetStateAction } from "react";

import {
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  makeStyles,
  createStyles,
  Hidden,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";

import "./WarningDialog.css";

import img from "../../assets/escritorio.svg";
import classes from "*.module.css";

export interface WarningDialogProps {
  title: string;
  message: string;
  state: [boolean, Dispatch<SetStateAction<boolean>>];
}

const useStyles = makeStyles(() =>
  createStyles({
    textColor: {
      color: "#1E1E1E",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function WarningDialog(props: WarningDialogProps) {
  const { message, title, state } = props;
  const [open, setOpen] = state;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="warning-dialog-slide-title"
      aria-describedby="warning-dialog-slide-description"
    >
      <Grid container justify="space-between">
        <Grid item md={8} xs={12}>
          <DialogTitle id="warning-dialog-slide-title">{title}</DialogTitle>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <DialogContent id="warning-dialog-slide-description">
              <DialogContentText className={classes.textColor}>
                {message}
              </DialogContentText>
            </DialogContent>
          </div>
        </Grid>
        <Hidden xsDown>
          <Grid item md={4}>
            <div style={{ padding: "5px" }}>
              <div id="warning-dialog-image">
                <img src={img}></img>
              </div>
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </Dialog>
  );
}

export default WarningDialog;
