import {
  createStyles,
  Grid,
  Hidden,
  makeStyles
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { Dispatch, SetStateAction } from "react";
import img from "../../assets/escritorio.svg";
import "./QuestionDialog.css";



/* eslint-disable-next-line */
export interface QuestionDialogProps {
  title: string;
  message: string;
  image?: string;
  labelButton1: string;
  labelButton2: string;
  handleClickButton1: () => void;
  handleClickButton2: () => void;
  state: [boolean, Dispatch<SetStateAction<boolean>>];
}

const useStyles = makeStyles(() =>
  createStyles({
    textColorPrimary: {
      color: "#1E1E1E",
    },
    textColorSecondary: {
      color: "#ffffff",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Exemplo de uso
 *
 * @example
 *        const [open, setOpen] = React.useState(false);
 * @example
 *      <QuestionDialog
 *          title="Olá,"
 *          message="Incoscistências, gostaria de entar pelo CPF ou tentar com o suporte técnico?"
 *          labelButton1="Tentar por cpf"
 *          labelButton2="Abrir solicitação"
 *          handleClickButton1={() => console.log("click button 1")}
 *          handleClickButton2={() => console.log("click button 2")}
 *          state={[open, setOpen]}
 *        />
 *
 *        <Button variant="contained" onClick={() => setOpen(true)}>Open</Button>
 *
 *
 * @param props
 */
export function QuestionDialog(props: QuestionDialogProps) {
  const {
    message,
    title,
    image,
    labelButton1,
    labelButton2,
    handleClickButton1,
    handleClickButton2,
    state,
  } = props;
  const [open, setOpen] = state;
  const classes = useStyles();

  const handleClose = (func: () => void) => {
    setOpen(false);
    func();
  };

  const clickButton1 = () => {
    handleClose(handleClickButton1);
  };

  const clickButton2 = () => {
    handleClose(handleClickButton2);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="question-dialog-title"
      aria-describedby="question-dialog-description"
    >
      <div id="question-dialog-container">
        <Grid container justify="space-between">
          <Grid item md={8} xs={12}>
            <DialogTitle id="question-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <Grid item xs={12}>
                <Grid container justify="space-between">
                  <Grid item xs={12}>
                    <DialogContentText
                      id="question-dialog-description"
                      className={classes.textColorPrimary}
                    >
                      {message.split("\\n").map(text => <span key={text}>{text}</span>)}
                    </DialogContentText>
                  </Grid>
                  <Grid>
                    <DialogActions id="question-dialog-actions">
                      <Button
                        variant="contained"
                        onClick={clickButton1}
                        color="secondary"
                      >
                        {labelButton1}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={clickButton2}
                        color="primary"
                      >
                        {labelButton2}
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>
          <Hidden xsDown>
            <Grid item md={4}>
              <div id="question-dialog-image">
                <img src={image ?? img} alt="escritorio"></img>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </Dialog>
  );
}

export default QuestionDialog;
