import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import App from "./app/App";
import GlobalStyles from "./theme/GlobalStyle";
import { DomainProvider, LoadingProvider } from "@syonet/gpd-hooks";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route
          path={`/:slug/`}
          render={() => (
            <DomainProvider>
              <GlobalStyles>
                <LoadingProvider>
                  <App />
                </LoadingProvider>
              </GlobalStyles>
            </DomainProvider>
          )}
        />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
