import React, { Component } from "react";
import { Typography, Container } from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useStyles } from "./BannerInfo.style";

export interface BannerInfoProps {
  text?: string;
}

export function BannerInfo({ text }: BannerInfoProps) {
  const classes = useStyles();

  return (
    <Container className={classes.cardInfo}>
      <InfoOutlinedIcon />
      <Typography variant="caption"> {text} </Typography>
    </Container>
  );
}

export default BannerInfo;
