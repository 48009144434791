import { makeStyles } from "@material-ui/core";
import React from "react";
import CustomTextField from "../../../custom-inputs/CustomTextField";
import SimpleStepsForm from "../../simple-steps-form/SimpleStepsForm";

import "./StepOneFrom.css";

/* eslint-disable-next-line */
export interface StepOneFromProps {
  step: number;
  onSubmit: (param) => void;
  inputRef?: React.Ref<unknown>;
}

const useStyles = makeStyles({
  colorPrimary: {
    color: "#1e1e1e !importante",
  },
});

export function StepOneFrom({ onSubmit, step }: StepOneFromProps) {
  const submitHandler = (data) => {
    onSubmit(data);
  };
  const classes = useStyles();

  return (
    <SimpleStepsForm
      description="Insira seu email ou telefone com DDD para procurar sua conta"
      button={{ label: "CONTINUE", onSubmit: submitHandler }}
      step={step}
    >
      <CustomTextField
        className={classes.colorPrimary}
        required
        id="step-one-field"
        name="email-telefone"
        variant="outlined"
      />
    </SimpleStepsForm>
  );
}

export default StepOneFrom;
