import { Button, Container, Typography } from "@material-ui/core";
import { DomainContext } from "@syonet/gpd-hooks";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./banner-lgpd.css";
import { useStyles } from "./BannerLgpd.style";

export interface BannerLgpdProps {
  text?: string;
}

export function BannerLgpd({ text }: BannerLgpdProps) {
  const { push } = useHistory();
  const { slug } = useContext(DomainContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const close = () => {
    localStorage.setItem(bannerLgpd, "disabled");
    setOpen(false);
  };

  function accessCookiesPolicy() {
    push(`/${slug}/politica-de-uso-de-cookies`);
  }

  useEffect(() => {
    const banner = localStorage.getItem(bannerLgpd);
    if (!banner) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return <div />;
  }
  return (
    <Container className={classes.cardInfo}>
      <Typography variant="caption"> {text} </Typography>
      <Button
        id="bt-cookie-policy"
        variant="outlined"
        color="secondary"
        onClick={() => accessCookiesPolicy()}
      >
        política de uso
      </Button>
      <Button
        id="bt-cookie-accept"
        variant="contained"
        color="primary"
        onClick={() => close()}
      >
        ok
      </Button>
    </Container>
  );
}
const bannerLgpd = "bannerlgpd";
export default BannerLgpd;
