import { AppBar, createStyles, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { MenuWithLogo, MenuWithLogoProps } from "../menu-with-logo/MenuWithLogo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
);

export function HeaderBar(props: MenuWithLogoProps) {
  const classes = useStyles();
  return (
    <AppBar
      className={clsx(classes.appBar, { [classes.appBarShift]: props.isOpen })}
    >
      <MenuWithLogo {...props} />
    </AppBar>
  );
}

export default HeaderBar;
