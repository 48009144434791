import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import { Radio, RadioProps, Theme, withStyles } from "@material-ui/core";

const CustomRadio = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.secondary.main,
    "&$checked": {
      color: "#1e1e1e",
    },
  },
  checked: {},
}))((props: RadioProps) => <Radio color="default" {...props} />);

export function CustomRadioButton({ name, checked, ...rest }: RadioProps) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return <CustomRadio checked={checked} name={name} {...rest} />;
}
export default CustomRadioButton;
