
  export function handleHashOnPathname (hash?: string) {
    const pathname = window.location.pathname.replace(/\/$/, "");
    if (hash) {
      history.replaceState("", null, pathname + "#" + hash);
      return;
    }
    history.replaceState("", null, pathname);
  }

  export default {
    handleHashOnPathname
  }
