import { makeStyles } from "@material-ui/core";

const useBannerStyles = makeStyles((theme) => ({
  bannerPadding: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    display: "flex",
    flexDirection: "column",
  },
  bannerBackground: {
    backgroundColor: theme.palette.primary.light,
    alignItems: "center",
    alignDirection: "row",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      alignDirection: "column-reverse",
      justifyContent: "center",
    },
  },
  bannerTypographyTitle: {
    fontWeight: "bold",
    letterSpacing: "-1.5%",
    marginTop: "1rem"
  },
  bannerTypographyDescription: {
    textAlign: "justify",
    marginBottom: "1rem",
    fontSize: "14px",
    fontWeight: 300,
    
  },
}));

export { useBannerStyles };
