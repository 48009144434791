import React, { ReactNode } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import "./MenuColumnForm.css";
import { Form } from "@unform/web";

/* eslint-disable-next-line */
export interface MenuColumnFormProps {
  title?: string;
  button?: {
    onSubmit: (data: any) => void;
  };
  children?: ReactNode;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "175px",
      height: "175px",
      padding: theme.spacing(1),
    },
    secondaryColor: { color: theme.palette.grey[900] },
  })
);

export function MenuColumnForm(props: MenuColumnFormProps) {
  const { children, button } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Form onSubmit={button.onSubmit} className="menu-column-class-center">
        {children}
      </Form>
    </Card>
  );
}

export default MenuColumnForm;
