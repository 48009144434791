import { InputAdornment } from "@material-ui/core";
import { VerifyCodeData } from "@syonet/gpd-hooks";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import CustomTextField from "../../../custom-inputs/CustomTextField";
import TextPhoneMask from "../../../masks/text-phone-mask/TextPhoneMask";
import SimpleStepsForm from "../../simple-steps-form/SimpleStepsForm";

import "./StepFourFrom.css";

/* eslint-disable-next-line */
export interface StepFourFromProps {
  step: number;
  onSubmit: (param: ResetPasswordDTO) => void;
}

export interface ResetPasswordDTO {
  passwordOne: string;
  passwordTwo: string;
  code: string;
  idPerson: number;
}

export function StepFourFrom({ onSubmit, step }: StepFourFromProps) {
  const { state } = useLocation<VerifyCodeData>();
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setpasswordTwo] = useState("");

  const submitHandler = () => {
    onSubmit({
      passwordOne,
      passwordTwo,
      code: state.code,
      idPerson: state.personId,
    });
  };

  return (
    <SimpleStepsForm
      description="Redefina sua senha"
      button={{ label: "CONTINUE", onSubmit: submitHandler }}
      step={step}
    >
      <CustomTextField
        required
        className="step-four-field"
        name="password-1"
        variant="outlined"
        placeholder="Nova senha"
        InputProps={{
          type: "password",
        }}
        onChange={(e) => setPasswordOne(e.target.value)}
      />
      <CustomTextField
        required
        className="step-four-field"
        name="password-2"
        variant="outlined"
        placeholder="Confirmar nova senha"
        InputProps={{
          type: "password",
        }}
        onChange={(e) => setpasswordTwo(e.target.value)}
      />
    </SimpleStepsForm>
  );
}

export default StepFourFrom;
