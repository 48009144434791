import {
  Box,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import SmsIcon from "@material-ui/icons/Sms";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import {
  ConfirmDialog,
  CustomSelect,
  MenuTitle,
  Option,
  QuestionDialog
} from "@portal-do-titular/ui-components";
import { LoadingContext, useApi } from "@syonet/gpd-hooks";
import { Form } from "@unform/web";
import React, { useContext, useRef, useState } from "react";
import image from "../../../assets/image1.svg";
import FormActions from "../formActions/FormActions";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignContent: "space-between",
      width: "100%",
      height: "70%",
      minWidth: "320px",
      overflowY: "scroll",
      overflowX: "hidden",
    },
    contactContainer: {
      height: "90%",
      maxHeight: "100%",
      overflowY: "scroll",
    },
    select: {
      width: "160px",
      margin: theme.spacing(0.5),
    },
    selectDrift: {
      width: "300px",
      margin: theme.spacing(0.5),
      [theme.breakpoints.down("xs")]: {
        width: "260px",
      },
    },
    selectContainer: {
      padding: theme.spacing(2),
      maxWidth: "340px",
      minWidth: "300px",
      [theme.breakpoints.down("xs")]: {
        width: "260px",
      },
    },
  })
);

export interface ContactPreferenceProps {
  onClose: () => void;
}

export interface ContactPreferenceState {
  betterContactPeriod: "MANHA" | "TARDE" | "NOITE";
  email: boolean;
  sms: boolean;
  telephone: boolean;
  whatsapp: boolean;
  justification: string;
}

export function ContactPreferenceForm({ onClose }: ContactPreferenceProps) {
  const initialContacts: Option[] = [
    { value: "PREFIRO", label: "Prefiro" },
    { value: "NAO_PREFIRO", label: "Não Prefiro" },
  ];

  const initialDrift: Option[] = [
    { value: "MANHA", label: "Manhâ" },
    { value: "TARDE", label: "Tarde" },
    { value: "NOITE", label: "Noite" },
  ];

  const toValue = (v: boolean) => {
    return v ? "PREFIRO" : "NAO_PREFIRO";
  };

  const [state, setState] = useState<ContactPreferenceState>(null);
  const [initState, setInitState] = useState<ContactPreferenceState>(null);
  const [openQuestionDialog, setOpenQuestionDialog] = useState(false);
  const [openSaveDialog, setSaveDialog] = useState(false);
  const [chaged, setChaged] = useState(false);
  const classes = useStyles();

  const formRef = useRef(null);
  const { api, handleRequest } = useApi();
  const { setIsLoading } = useContext(LoadingContext);

  const mapper = (data: Option[], value: string) => {
    return data.map((o) => ({ ...o, selected: o.value === value }));
  };

  const handleDriftOptions = (v: "MANHA" | "TARDE" | "NOITE") =>
    mapper(initialDrift, v);
  const handleWhatsappOptions = (v: boolean) =>
    mapper(initialContacts, toValue(v));
  const handleTelephoneOptions = (v: boolean) =>
    mapper(initialContacts, toValue(v));
  const handleSmsOptions = (v: boolean) => mapper(initialContacts, toValue(v));
  const handleEmailOptions = (v: boolean) =>
    mapper(initialContacts, toValue(v));

  React.useEffect(() => {
    setIsLoading(true);
    const cleanState = {} as ContactPreferenceState;
    handleRequest(() => api.get("v1/person/preference"))
      .then(({ data, status }) => {
        if (status > 400) {
          setInitState(cleanState);
        }
        setInitState(data || cleanState);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = (form) => {
    const result = {} as ContactPreferenceState;
    Object.keys(form).forEach((key) => (result[key] = "PREFIRO" === form[key]));
    result["betterContactPeriod"] = form["betterContactPeriod"];
    setState(result);
    setSaveDialog(true);
  };

  const onSuccess = () => {
    state.justification = "Alterado pelo cliente via Portal do Titular.";
    handleRequest(() => api.post("v1/person/preference", state));
    onClose();
  };

  const onCancel = () => {
    if (chaged) {
      setOpenQuestionDialog(true);
      return;
    }
    onClose();
  };

  const onChangeHandler = (e) => {
    setChaged(true);
  };

  return (
    <>
      <Container className={classes.contactContainer}>
        <ConfirmDialog
          title="Eba!!!"
          message="Suas preferências de contato foram salvas sucesso. "
          hasButton
          labelButton="Ok"
          handleClickButton={onSuccess}
          state={[openSaveDialog, setSaveDialog]}
        />
        <QuestionDialog
          title="Opa!!!"
          image={image}
          message="Notamos que você quer retornar ao menu, mas houveram alterações e será necessário escolher umas das opções abaixo para prosseguir."
          labelButton1="voltar ao menu"
          labelButton2="salvar e voltar"
          handleClickButton1={onClose}
          handleClickButton2={() => formRef.current.submitForm()}
          state={[openQuestionDialog, setOpenQuestionDialog]}
        />
        <MenuTitle title="Preferência de contato" />
        <Box className={classes.root}>
          <Form ref={formRef} onSubmit={onSave}>
            <Paper>
              <Grid container direction="column">
                {initState && (
                  <>
                    <Grid item>
                      <Box className={classes.selectContainer}>
                        <CustomSelect
                          labelVariant="subtitle2"
                          className={classes.selectDrift}
                          name="betterContactPeriod"
                          label="Melhor horário para contato"
                          alignItems="flex-start"
                          direction="column"
                          onChange={onChangeHandler}
                          options={handleDriftOptions(
                            initState.betterContactPeriod
                          )}
                          defaultValue={handleDriftOptions(
                            initState.betterContactPeriod
                          )?.find((o) => o.selected)}
                        ></CustomSelect>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className={classes.selectContainer}>
                        <Typography variant="subtitle2">Tipo de contato</Typography>
                        <CustomSelect
                          icon={<WhatsAppIcon />}
                          className={classes.select}
                          onChange={onChangeHandler}
                          labelVariant="caption"
                          name="whatsapp"
                          label="WhatsApp"
                          justify="space-between"
                          alignItems="center"
                          options={handleWhatsappOptions(initState.whatsapp)}
                          defaultValue={handleWhatsappOptions(
                            initState.whatsapp
                          )?.find((o) => o.selected)}
                        ></CustomSelect>
                        <CustomSelect
                          icon={<PhoneIcon />}
                          className={classes.select}
                          onChange={onChangeHandler}
                          labelVariant="caption"
                          name="telephone"
                          label="Telefonema"
                          justify="space-between"
                          alignItems="center"
                          options={handleTelephoneOptions(initState.telephone)}
                          defaultValue={handleTelephoneOptions(
                            initState.telephone
                          )?.find((o) => o.selected)}
                        ></CustomSelect>
                        <CustomSelect
                          icon={<SmsIcon />}
                          className={classes.select}
                          onChange={onChangeHandler}
                          labelVariant="caption"
                          name="sms"
                          label="SMS"
                          justify="space-between"
                          alignItems="center"
                          options={handleSmsOptions(initState.sms)}
                          defaultValue={handleSmsOptions(initState.sms)?.find(
                            (o) => o.selected
                          )}
                        ></CustomSelect>
                        <CustomSelect
                          icon={<EmailIcon />}
                          className={classes.select}
                          onChange={onChangeHandler}
                          labelVariant="caption"
                          name="email"
                          label="E-mail"
                          justify="space-between"
                          alignItems="center"
                          options={handleEmailOptions(initState.email)}
                          defaultValue={handleEmailOptions(initState.email)?.find(
                            (o) => o.selected
                          )}
                        ></CustomSelect>
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            </Paper>
          </Form>
        </Box>
      </Container>
      <FormActions confirm={() => formRef.current.submitForm()} cancel={onCancel} />
    </>
  );
}