import { Box, Card, Grid, Typography } from "@material-ui/core";
import React from "react";

import "./ConfirmCodeForm.css";
import CodeForm from "../code-form/CodeForm";

export interface ConfirmCodeDTO {
  code1: string;
  code2: string;
  code3: string;
  code4: string;
}

export interface ConfirmCodeFormProps {
  onSubmit: (param: string) => void;
}

export function ConfirmCodeForm({ onSubmit }: ConfirmCodeFormProps) {
  return (
    <Card style={{ height: "100%" }} id="confirm-code-root">
      <Box
        display="flex"
        height="100%"
        alignItems="center"
        flexDirection="column"
        px={4}
        py={2}
      >
        <Box pt={2}>
          <Typography className="confirm-code-text" variant="h5" paragraph>
            Insira aqui o código recebido
          </Typography>
        </Box>
        <Box pb={4}>
          <Typography className="confirm-code-text" paragraph>
            Ao receber o código de segurança via SMS ou por E-mail, insira nos
            campos abaixo.
          </Typography>
        </Box>
        <Box id="confirm-code-form" mb={5}>
          <CodeForm onSubmit={onSubmit} />
        </Box>
      </Box>
    </Card>
  );
}

export default ConfirmCodeForm;
