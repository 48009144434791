import React from "react";
import "../menu-column-form/MenuColumnForm.css";
import MenuColumnForm from "../menu-column-form/MenuColumnForm";
import dataSharingConsult from "../../../assets/dataSharingConsult.svg";
import { useStyles } from "../menu-column-form/MenuColumnForm";

export interface DataSharingConsultDTO {
  label: string;
}

export interface DataSharingConsultProps {
  onSubmit: (param: DataSharingConsultDTO) => void;
}

export function DataSharingConsult({ onSubmit }: DataSharingConsultProps) {
  const classes = useStyles();
  const submitHandler = (data: DataSharingConsultDTO) => {
    onSubmit(data);
  };

  return (
    <MenuColumnForm button={{ onSubmit: submitHandler }}>
      <img
        id="menu-column-icon"
        src={dataSharingConsult}
        alt="sharing connections"
      />
      <div id="menu-column-title" className={classes.secondaryColor}>
        Consulta de compartilhamento de dados
      </div>
    </MenuColumnForm>
  );
}

export default DataSharingConsult;
