import React from "react";
import GenericTextMask, { GenericTextMaskProps } from '../generic-text-mask/GenericTextMask';

import "./TextCPFMask.css";

/* eslint-disable-next-line */
export interface TextCPFMaskProps { }

export function TextCPFMask(props: GenericTextMaskProps) {
  return (
    <GenericTextMask
      {...props}
      mask={[/[0-9]/, /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'} />
  );
}

export default TextCPFMask;
