import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textButton: {
      fontFamily: "Roboto",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "22px",
      letterSpacing: "0.46px",
    },
    text: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "36px",
      letterSpacing: "-0.015em",
      textTransform: "capitalize",
    },
    button: {
      width: "136px",
      height: "36px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "4px 10px",
      marginLeft: "72px",
      borderRadius: "60px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
      },
    },
  })
);
export { useStyles };
