import { Box, InputAdornment } from "@material-ui/core";
import React from "react";
import OneColumnForm from "../../forms/one-column-form/OneColumnForm";
import TextCPFMask from "../../masks/text-cpf-mask/TextCPFMask";

import "./CPFForm.css";

import LockIcon from "@material-ui/icons/Lock";
import CustomTextField from "../../custom-inputs/CustomTextField";
import { PinDropSharp } from "@material-ui/icons";

export interface CPFFormDTO {
  cpf: string;
}

export interface CPFFormProps {
  onSubmit: (params: CPFFormDTO) => void;
  inputRef?: React.Ref<unknown>;
  minHeight?: number;
  minWidth?: number;
}

export function CPFForm({
  onSubmit,
  inputRef,
  minHeight,
  minWidth,
}: CPFFormProps) {
  const submitHandler = (data: CPFFormDTO) => {
    onSubmit(data);
  };

  return (
    <OneColumnForm
      button={{ label: "Continuar", onSubmit: submitHandler }}
      title="CPF"
      minHeight={minHeight}
      minWidth={minWidth}
    >
      <Box className="text-input-conteiner-cpf">
        <CustomTextField
          fullWidth
          margin="dense"
          required
          name="cpf"
          variant="outlined"
          placeholder="CPF"
          InputProps={{
            type: "tel",
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
            inputComponent: TextCPFMask,
          }}
        />
        <Box style={{ marginTop: "58px" }} />
      </Box>
    </OneColumnForm>
  );
}

export default CPFForm;
