import { Box, Typography, Link, Hidden } from "@material-ui/core";
import React from "react";

import contratoImage from "../../assets/consentimento.svg";
import direitosImage from "../../assets/direitos.svg";
import { useKnowMoreStyles } from "./KnowMore.style";

/* eslint-disable-next-line */
export interface KnowMoreProps { }

export function KnowMore(props: KnowMoreProps) {
  const classes = useKnowMoreStyles();
  return (
    <Box className={classes.rootContainer}>
      <Box className={classes.containerOne}>
        <Typography className={`${classes.textOne} ${classes.textBasic}`}>
          DO QUE TRATA
          <br /> ESTE PORTAL?
        </Typography>
        <Box className={classes.insideBoxOne}>
          <Box className={classes.line} />
          <Typography className={`${classes.textTwo} ${classes.textBasic}`}>
          Este portal tem o objetivo de viabilizar o atendimento aos <b>Titulares 
          dos Dados</b>, bem como o acompanhamento de suas dúvidas e/ou solicitações 
          a respeito do tratamento dos seus dados pessoais realizados por nós
          </Typography>
        </Box>
      </Box>
      <Box className={classes.containerTwo}>
        <Hidden smDown>
          <Box className={classes.insideboxTwo}>
            <Typography
              className={`${classes.textThree} ${classes.textBasic}`}
              color="primary"  style={{ fontSize: "17px" }}
            >
              No que consiste a Lei Geral de Proteção de Dados Pessoais - LGPD?
            </Typography>
            <Typography
              style={{ marginBottom: "16px" }}
              className={`${classes.textFour} ${classes.textBasic}`}
            >
              A Lei Geral de Proteção de Dados Pessoais, Lei nº 13.709/2018,
               é a legislação brasileira que regula o tratamento de dados pessoais, 
               a fim de garantir a privacidade das pessoas naturais. Esta lei, 
               concede ao titular dos dados uma série de direitos, ainda que não absolutos, 
               que permitem aos indivíduos monitorar o tratamento dos seus dados pessoais.
            </Typography>
            <Link
              className={classes.link}
              target="_blank"
              rel="noreferrer"
              href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709compilado.htm"
            >
              Nesse link você encontra o texto integral da LGPD.
            </Link>
          </Box>
          <Box className={classes.insideboxTwo}>
            <img
              src={contratoImage}
              alt="person with a big note"
              className={classes.imageOne}
            />
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box className={classes.insideboxImg}>
            <img
              src={contratoImage}
              alt="person with a big note"
              className={classes.imageOne}
            />
          </Box>
          <Box className={classes.insideboxTwo}>
            <Typography
              className={`${classes.textThree} ${classes.textBasic}`}
              color="primary"
            >
              No que consiste a Lei Geral de Proteção de Dados Pessoais - LGPD?
            </Typography>
            <Typography
              style={{ marginBottom: "16px" }}
              className={`${classes.textFour} ${classes.textBasic}`}
            >              
              A Lei Geral de Proteção de Dados Pessoais, Lei nº 13.709/2018,
               é a legislação brasileira que regula o tratamento de dados pessoais, 
               a fim de garantir a privacidade das pessoas naturais. Esta lei, 
               concede ao titular dos dados uma série de direitos, ainda que não absolutos, 
               que permitem aos indivíduos monitorar o tratamento dos seus dados pessoais.
            </Typography>
            <Link
              className={classes.link}
              target="_blank"
              rel="noreferrer"
              href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709compilado.htm"
            >
              Nesse link você encontra o texto integral da LGPD.
            </Link>
          </Box>
        </Hidden>
      </Box>
      <Box className={classes.containerTwo}>
        <Box className={classes.insideboxImg}>
          <img
            src={direitosImage}
            alt="person with a old balance"
            className={classes.imageTwo}
          />
        </Box>
        <Box className={classes.insideboxTwo}>
          <Typography
            className={`${classes.textThree} ${classes.textBasic}`}
            color="primary"
          >
            Nomenclaturas importantes para você saber:
          </Typography>
          <Typography className={`${classes.textFour} ${classes.textBasic}`}>
            <b>Titular:</b> É o dono dos dados pessoais, ou seja, você.
          </Typography>
          <Typography className={`${classes.textFour} ${classes.textBasic}`}>
            <b>Controlador:</b> É o responsável por definir quais e como os dados pessoais serão tratados. Neste caso, nós somos o Controlador dos dados.
          </Typography>
          <Typography className={`${classes.textFour} ${classes.textBasic}`}>
            <b>DPO (Data Protection Officer) ou Encarregado:</b> É a pessoa indicada pelo 
            Controlador para estabelecer o processo de conformidade com a LGPD, bem como 
            atuar, interagir e ser o elo de comunicação entre o Controlador, 
            os Titulares dos Dados e a Autoridade Nacional de Proteção de Dados (ANPD).
          </Typography>
          <Typography  className={`${classes.textFour} ${classes.textBasic}`}>
            <b>Tratamento:</b> Toda operação realizada com os dados pessoais que permite identificar uma pessoa natural.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default KnowMore;
