import React, { Dispatch, SetStateAction } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { createStyles, Grid, makeStyles } from "@material-ui/core";

import { TransitionProps } from "@material-ui/core/transitions";

import img from "../../assets/ErrorDialog.jpg";

import "./ErrorDialog.css";

/* eslint-disable-next-line */
export interface ErrorDialogProps {
  title: string;
  message: string;
  state: [boolean, Dispatch<SetStateAction<boolean>>];
}

const useStyles = makeStyles(() =>
  createStyles({
    textColor: {
      color: "#1E1E1E",
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ErrorDialog(props: ErrorDialogProps) {
  const { message, title, state } = props;
  const [open, setOpen] = state;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="error-dialog-slide-title"
      aria-describedby="error-dialog-slide-description"
    >
      <Grid container justify="space-between">
        <Grid item xs={9}>
          <DialogTitle id="error-dialog-slide-title">{title}</DialogTitle>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <DialogContent id="error-dialog-slide-description">
              <DialogContentText className={classes.textColor}>
                {message}
              </DialogContentText>
            </DialogContent>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div style={{ padding: "5px" }}>
            <div id="error-dialog-image">
              <img src={img} alt="Person estuding in a table"></img>
            </div>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default ErrorDialog;
