import { InputAdornment } from "@material-ui/core";
import React, { useContext, useState } from "react";
import CustomTextField from "../../../custom-inputs/CustomTextField";
import TextPhoneMask from "../../../masks/text-phone-mask/TextPhoneMask";
import SimpleStepsForm from "../../simple-steps-form/SimpleStepsForm";

import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";

import "./StepTreeFrom.css";
import ConfirmCodeForm from "../../confirm-code-form/ConfirmCodeForm";
import CodeForm from "../../code-form/CodeForm";
import { AuthContext, useAuthCode, VerifyCodeData } from "@syonet/gpd-hooks";
import { error } from "console";
import { useLocation } from "react-router-dom";
import { sendDTO } from "../step-two-from/StepTwoFrom";

/* eslint-disable-next-line */
export interface StepTreeFromProps {
  step: number;
  onSubmit: (param: VerifyCodeData) => void;
}

export function StepTreeFrom({ onSubmit, step }: StepTreeFromProps) {
  const { state } = useLocation<sendDTO>();

  const submitHandler = (data: string) => {
    onSubmit({ code: data, personId: state.personId, invalidate: false });
  };

  return (
    <SimpleStepsForm description="Insira o código recebido" step={step}>
      <CodeForm onSubmit={submitHandler} />
    </SimpleStepsForm>
  );
}

export default StepTreeFrom;
