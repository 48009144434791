import { createStyles, Hidden, makeStyles, Theme } from "@material-ui/core";

const useHomeStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    buttonText: {
      color: theme.palette.primary.contrastText,
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginTop: "80px",
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginTop: "318px",
    },
    homeContainer: {
      backgroundColor: theme.palette.background.default,
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
    },
    homeMainBackground: {
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.up("md")]: {
        height: "90vh",
      },
    },
    homeTextSecondaryColor: {
      color: theme.palette.secondary.contrastText,
    },
    homeTextExplanation: {
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "43px",
    },
    homeTextExplanationContainer: {
      width: "1070px",
      height: "43px",
      alignItems: "center",
      paddingLeft: "10px",
      marginBottom: "24px",
      backgroundColor: theme.palette.primary.light,
      [theme.breakpoints.down("md")]: {
        width: "calc(90vw - 10px)",
        height: "fit-content",
        margin: "24px auto",
      },
    },
    mainTextColor: {
      color: theme.palette.primary.contrastText,
    },
  })
);

export { useHomeStyles };
