import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useKnowMoreStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerOne: {
      width: "1154px",
      margin: "31px auto 0 auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "17px 50px",
      background: theme.palette.primary.light,
      [theme.breakpoints.down("md")]: {
        width: "calc(90vw - 10px)",
        height: "fit-content",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: "20px",
      },
    },
    containerTwo: {
      width: "1154px",
      margin: "31px auto 0 auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "17px 50px",
      [theme.breakpoints.down("md")]: {
        width: "calc(90vw - 10px)",
        height: "fit-content",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: "24px",
        margin: "31px 0 0 0",
      },
    },
    imageOne: {
      width: "252px",
      height: "212px",
      [theme.breakpoints.down("sm")]: {
        width: "175px",
        height: "147px",
      },
    },
    imageTwo: {
      width: "186px",
      height: "186px",
      [theme.breakpoints.down("sm")]: {
        width: "129px",
        height: "129px",
      },
    },
    insideBoxOne: {
      width: "520px",
      [theme.breakpoints.down("md")]: {
        width: "98%",
      },
    },
    insideboxTwo: {
      width: "577px",
      paddingRight: "20px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "90vw",
      },
    },
    insideboxImg: {
      width: "577px",
      paddingRight: "20px",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        maxWidth: "90vw",
      },
    },
    line: {
      width: "250px",
      border: "1px solid #000000",
      marginBottom: "32px",
      [theme.breakpoints.down("md")]: {
        width: "96%",
      },
    },
    link: {
      fontFamily: "Roboto, sans-serif",
      color: "#0B79D0",
      textDecoration: "underline",
    },
    textBasic: {
      fontFamily: "Roboto",
      textAlign: "justify",
      letterSpacing: "-0.015em",
    },
    textOne: {
      fontWeight: 700,
      fontSize: "36px",
      lineHeight: "64px",
      [theme.breakpoints.down("md")]: {
        fontSize: "26px",
        lineHeight: "40px",
      },
    },
    textTwo: {
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "30px",
    },
    textThree: {
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "30px",
      paddingBottom: "16px",
    },
    textFour: {
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "30px",
    },
    rootContainer: {
      width: "98vw",
    },
  })
);

export { useKnowMoreStyles };
