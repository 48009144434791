import { Box, Container, createStyles, Hidden, makeStyles, Theme } from "@material-ui/core";
import { Copyright, HeaderBar, MenuKnowMore, SystemHeaderSmart } from "@portal-do-titular/ui-components";
import React, { ReactNode } from "react";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      maxWidth: "100%",
    },
    main: {
      margin: 0,
      padding: 0,
      display: "flex",
      alignItems: "stretch",
      height: "100%"
    },
    wrapperData: {
      width: "36%",
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      height: "100vh",
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "0px 20px 20px 0px",
      padding: "20px 20px 0 60px",
    },
    dataHeader: {
    },
    dataContent: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    wrapperContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: "20px 60px 0 20px",
      [theme.breakpoints.up("md")]: {
        width: "64%",
      },
      [theme.breakpoints.down("md")]: {
        padding: 0,
      },
    },
    contentHeader: {
      height: "60px",
    },
    content: {
      overflowY: "auto",
      padding: theme.spacing(1),
      height: "calc(100vh - 80px)",
      [theme.breakpoints.down("md")]: {
        padding: 0,
      },
    }
  })
);

function BoxFooter() {
  return (
    <Box>
      <Copyright />
    </Box>
  );
}
function ColumnsLayout(props: {
  data: ReactNode,
  children: ReactNode
  hasLogout?: boolean,
  id?: string;
  contentId?: string;
}) {
  const classes = useStyles();
  const [statusMenu, setStatusMenu] = React.useState(false);
  const openMenu = () => setStatusMenu(true);
  const closeMenu = () => setStatusMenu(false);
  const elementId = props.id || `layout-${Date.now()}`;
  const elementContentId = props.contentId || `layout-content${Date.now()}`;
  return (
    <Container className={classes.root} disableGutters id={elementId} >
      <Hidden mdUp >
        <HeaderBar
          onOpen={openMenu}
          onClose={closeMenu}
          isOpen={statusMenu}
          isLogout={props.hasLogout}
        />
      </Hidden>
      <Box className={classes.main} >
        <Hidden smDown >
          <Box className={classes.wrapperData} >
            <Box className={classes.dataHeader} >
              <SystemHeaderSmart />
            </Box>
            <Box className={classes.dataContent} >
              <Box>
                {props.data}
              </Box>
            </Box>
            <BoxFooter />
          </Box>
        </Hidden>
        <Box className={classes.wrapperContent} >
          <Hidden smDown >
            <Box className={classes.contentHeader} >
              <MenuKnowMore hasLogout={props.hasLogout} />
            </Box>
          </Hidden>
          <Box className={classes.content} id={elementContentId} >
            {props.children}
          </Box>
          <Hidden mdUp >
            <BoxFooter />
          </Hidden>
        </Box>
      </Box>
    </Container>
  );
}

export default ColumnsLayout;
