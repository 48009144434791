import {
  Box,
  Button,
  createStyles,
  Hidden,
  makeStyles,
  Theme,
} from "@material-ui/core";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import Menu from "../menu/Menu";
import MenuDrawer from "../menu-drawer/MenuDrawer";

import "./MenuWithLogo.css";

import MenuSectionLogo from "../menu-section-logo/MenuSectionLogo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textColor: {
      color: theme.palette.text.primary,
    },
  })
);

/* eslint-disable-next-line */
export interface MenuWithLogoProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onKnowMore?: () => void;
  onHome?: () => void;
  isLogout?: boolean;
}

export function MenuWithLogo(props: MenuWithLogoProps) {
  const { isOpen, onClose, onOpen, onHome, onKnowMore, isLogout } = props;
  const classes = useStyles();

  return (
    <Box className="menu-with-logo-header" >
      <MenuSectionLogo
        boxProps={{
          color: classes.textColor,
          className: clsx(isOpen && "hide"),
        }}
      />
      <Hidden mdUp>
        <section
          className={`menu-with-logo-menu-section ${clsx(isOpen && "hide")}`}
        >
          <Button onClick={() => onOpen()}>
            <MenuIcon />
          </Button>
        </section>
      </Hidden>
      {isOpen && (
        <MenuDrawer
          onKnowMore={onKnowMore}
          onHome={onHome}
          open={isOpen}
          onClose={() => onClose()}
          isLogout={isLogout}
        />
      )}
      <Hidden smDown >
        <section className={`menu-with-logo-menu-section menu-items`} >
          <Menu
            onKnowMore={onKnowMore}
            onHome={onHome}
          />
        </section>
      </Hidden>
    </Box>
  );
}

export default MenuWithLogo;
