import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useMenuDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsContainer: {
      height: "260px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      paddingBottom: "40px",
    },
    container: {
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
    },
    closeContainer: {
      width: "100vw",
      height: "80px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    textButton: {
      fontFamily: "Roboto",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "22px",
      textTransform: "uppercase",
      letterSpacing: "0.46px",
      color: theme.palette.secondary.main,
    },
    text: {
      fontFamily: "Montserrat",
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "36px",
      letterSpacing: "-0.015em",
      textTransform: "capitalize",
    },
    button: {
      width: "136px",
      height: "36px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "4px 10px",
      borderRadius: "60px",
    },
    textColor: {
      color: theme.palette.text.primary,
    },
    hide: {
      display: "none",
    },
  })
);

export { useMenuDrawerStyles };
