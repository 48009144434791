import { LinearProgress } from "@material-ui/core";
import { CreateAccountDTO, CreateYourLogin, MessageBlock } from "@portal-do-titular/ui-components";
import { AuthContext, DomainContext, usePerson } from "@syonet/gpd-hooks";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ColumnsLayout from "../layout/ColumnsLayout";
import "./Register.css";


function DataSection() {
  const { create } = usePerson();
  const { push } = useHistory();
  const { slug } = useContext(DomainContext);
  const { signIn, signed } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (signed) {
      push(`/${slug}/autenticacao-dupla`);
    }
  }, [slug, push, signed]);
  const onSubmit = ({ name, email, password }: CreateAccountDTO) => {
    const action = async () => {
      setLoading(true);
      const { status } = await create({ name, email, password });
      if (status === 409) {
        push(`/${slug}/login`, { email });
        return;
      }
      await signIn({ email, password });
    };
    action();
    return () => setLoading(false);
  };
  return (
    <>
      <CreateYourLogin onSubmit={onSubmit} />
      {loading && <LinearProgress />}
    </>
  )
}

function WelcomeToSystem() {
  const title = "Seja muito bem-vindo (a) ao Portal do Titular.";
  const message = "É um prazer receber você em nosso portal."
    + " Estamos muito felizes em, juntamente com você, proteger seus dados.";
  return (
    <MessageBlock
      title={title}
      message={message}
    />
  );
}

function Register() {
  return (
    <ColumnsLayout
      data={WelcomeToSystem}
    >
      <DataSection />
    </ColumnsLayout>
  );
}

export default Register;
