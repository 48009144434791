import { Box, Container, Typography, useTheme } from "@material-ui/core";
import {
  Banner,
  ConfirmDialog,
  CPFForm,
  EmailAndPhoneForm,
  Copyright,
  HeaderBar,
  PageContent,
  QuestionDialog,
  GenericProtocolDialog,
} from "@portal-do-titular/ui-components";
import { useHistory, useRouteMatch } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import useStyles from "./DualAuthentication.style";
import peopleAuth from "../../../assets/peopleAuth.svg";
import {
  AuthContext,
  useApi,
  DomainContext,
  PersonCRM,
  LoadingContext,
} from "@syonet/gpd-hooks";

/* eslint-disable-next-line */
export interface DualAuthenticationProps { }

export function DualAuthentication(props: DualAuthenticationProps) {
  const classes = useStyles();
  const { logout, signed, person } = useContext(AuthContext);
  const { push } = useHistory();
  const { path: pathWithoutDomain } = useRouteMatch();
  const { injectSlug, slug } = useContext(DomainContext);
  const { api } = useApi();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPersonDialog, setOpenPersonDialog] = useState(false);
  const [personCRM, setPersonCRM] = useState<PersonCRM>(null);
  const [openQuestionDialog, setOpenQuestionDialog] = useState(false);
  const [openConfirmProtocolDialog, setOpenConfirmProtocolDialog] = useState(
    false
  );

  const [openGenericProtocolDialog, setOpenGenericProtocolDialog] = useState(
    false
  );
  const { setIsLoading } = useContext(LoadingContext);

  const path = injectSlug(pathWithoutDomain);
  const {
    palette: { secondary },
  } = useTheme();
  useEffect(() => {
    if (signed && !person?.isActive) {
      return;
    }
    push(`/${slug}/login`);
  }, [slug, person?.isActive, push, signed]);

  const handleFindPerson = (person: PersonCRM) => {
    setIsLoading(false);
    if (!person) {
      setOpenQuestionDialog(true);
      return;
    }
    if (person.isActive) {
      setOpenDialog(true);
      return;
    }

    setOpenPersonDialog(true);
    setPersonCRM(person);
  };

  const handleCPFSubmit = ({ cpf }: { cpf: string }) => {
    setIsLoading(true);
    api
      .get("/v1/account/searchClient", {
        params: {
          cpf: cpf.match(/\d+/g).join(""),
          email: person.email,
        },
      })
      .then(({ data }) => data)
      .then(handleFindPerson)
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
        setOpenQuestionDialog(true);
      });
  };

  const handleEmailAndPhoneSubmit = async ({
    email,
    phone,
  }: {
    email: string;
    phone: string;
  }) => {
    setIsLoading(true);
    const cleanPhone = phone.match(/\d+/g).join("");
    const ddd = cleanPhone.substring(0, 2);
    const telephone = cleanPhone.substring(2);
    api
      .get("/v1/account/searchClient", {
        params: {
          email: person.email,
          emailCrm: email,
          telephone,
          ddd,
        },
      })
      .then(({ data }) => data)
      .then(handleFindPerson)
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
        setOpenQuestionDialog(true);
      });
  };

  const handleProtocol = () => {
    setOpenQuestionDialog(false);
    setOpenGenericProtocolDialog(true);
  };

  const handleProtocolCreate = () => {
    setOpenGenericProtocolDialog(false);
    setOpenConfirmProtocolDialog(true);
  };

  const handleDialog = (param) => {
    setOpenDialog(param);
    logout();
  };

  const handleDrawerOpen = () => {
    setOpenMenu(true);
  };

  const handleDrawerClose = () => {
    setOpenMenu(false);
  };

  return (
    <Box className={classes.root}>
      <HeaderBar
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
        isOpen={openMenu}
      />
      <PageContent>
        <ConfirmDialog
          title="Olá,"
          message="Conta encontra-se ativada, por favor realize o login!"
          state={[openDialog, handleDialog]}
        />
        <QuestionDialog
          title="Olá,"
          message="Informamos que seus dados não foram localizados em nosso banco de dados ativo OU existe alguma inconsistência no seu preenchimento.\nVocê gostaria de tentar realizar uma nova busca ou abrir uma solicitação diretamente junto ao nosso DPO?\nFavor escolher a opção desejada abaixo:"
          labelButton1="Tentar Novamente"
          labelButton2="Abrir solicitação"
          handleClickButton1={() => console.log("Tentar novamente!")}
          handleClickButton2={() => handleProtocol()}
          state={[openQuestionDialog, setOpenQuestionDialog]}
        />
        <ConfirmDialog
          title="Olá,"
          message="Encontramos em nossa base de dados um cadastro com o(s) dado(s) inserido(s), temos mais um passo para comprovar que você é você mesmo. Para isso escolha uma das opções para receber o código validador, assim que receber adicione no campo indicativo e a validação está finalizada."
          state={[openPersonDialog, setOpenPersonDialog]}
          currentWidth="139px"
          hasButton
          labelButton="PROSSEGUIR"
          handleClickButton={() => push(`${path}/confirmar-dados`, personCRM)}
        />
        <ConfirmDialog
          title="Olá,"
          message="Seu protocolo foi criado com sucesso, aguarde o contato do DPO"
          state={[openConfirmProtocolDialog, setOpenConfirmProtocolDialog]}
        />
        <GenericProtocolDialog
          handleProtocolCreate={() => handleProtocolCreate()}
          state={[openGenericProtocolDialog, setOpenGenericProtocolDialog]}
        />
        <Box p={2} >
          <Banner
            title="PORTAL DO TITULAR"
            img={peopleAuth}
            description="Seja muito bem-vindo(a) ao nosso Portal do Titular, é um prazer receber você.\nEstamos muito felizes em te ajudar a proteger seus dados pessoais. Porém, para te auxiliarmos nesta tarefa, precisamos verificar se você possui cadastro ativo conosco. Para isso, precisamos que você preencha, logo abaixo, o seu CPF OU o seu número de telefone MAIS o seu e-mail, para que o sistema possa verificar a existência de seus dados pessoais em nosso banco de dados."
            text="Se os seus dados não forem localizados em nosso sistema, você receberá um aviso de “não localizado”."
            subText="De qualquer forma, você poderá, caso queira, entrar em contato direto, a qualquer tempo, com o nosso DPO para eventuais solicitações ou esclarecimentos, o mesmo retornará seu contato assim que possível."
          />

        </Box>
        <section className={classes.formSection}>
          <EmailAndPhoneForm onSubmit={handleEmailAndPhoneSubmit} />
          <Typography variant="subtitle2">OU</Typography>
          <CPFForm onSubmit={handleCPFSubmit} />
        </section>
      </PageContent>
      <div className={classes.footer}>
        <Container maxWidth="lg">
          <Copyright />
        </Container>
      </div>
    </Box>
  );
}

export default DualAuthentication;
