import React, { createContext, useState } from "react";
import { GroupByProcess, PreferencesData, useConsent } from "./useConsent";

interface MenusContextData {
  finalities: GroupByProcess[];
  preferences: PreferencesData[];
  consentManager: (crmId: number) => Promise<void>;
}

export const MenusContext = createContext<MenusContextData>(
  {} as MenusContextData
);

export const MenusProvider = ({ children }) => {
  const [groups, setGroups] = useState<GroupByProcess[]>(null);
  const [allPreferences, setAllPreferences] = useState<PreferencesData[]>(null);
  const { getFinalities, getPreferences, getGroupedFinalities } = useConsent();

  async function getConsentInfo(crmId: number) {
    const finalities = await getFinalities(crmId);
    const groupedFinalities = getGroupedFinalities(finalities, crmId);
    const newPreferences = getPreferences(finalities, crmId);
    setGroups(groupedFinalities);
    setAllPreferences(newPreferences);
  }

  return (
    <MenusContext.Provider
      value={{
        finalities: groups,
        preferences: allPreferences,
        consentManager: getConsentInfo,
      }}
    >
      {children}
    </MenusContext.Provider>
  );
};
