import { Box, Typography } from "@material-ui/core";
import { AccountCircle, Email, Phone, SvgIconComponent } from "@material-ui/icons";
import React from "react";
import { useStyles } from "./PersonInfoCard.style";

function PersonDetail(props: {
  text: string,
  CustonIcon: SvgIconComponent,
}) {
  const classes = useStyles();
  const { text, CustonIcon } = props;
  return (
    <Box className={classes.personalDetails} >
      <CustonIcon className={classes.detailIcon} />
      <Typography className={classes.text} variant="body1" >
        {text}
      </Typography>
    </Box>
  );
}

function PersonHeader(props: {
  name: string
}) {
  const classes = useStyles();
  return (
    <Box className={classes.personalMessageBlockHeader} >
      <AccountCircle className={classes.personIcon} />
      <Box>
        <Typography variant="h5" className={classes.text}>
          {props.name}
        </Typography>
      </Box>
    </Box>
  );
}

export function PersonInfoCard({ name, ddd, telephone, email }) {
  const classes = useStyles();
  const phone = `(${ddd}) ${telephone}`.replace("\() ", "").trim();
  return (
    <Box className={classes.content} >
      <PersonHeader name={name} />
      <Box className={classes.body} >
        {email && <PersonDetail text={email} CustonIcon={Email} />}
        {phone && <PersonDetail text={phone} CustonIcon={Phone} />}
      </Box>
    </Box>
  );
}
