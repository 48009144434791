import { Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import React, { useContext } from "react";

import { AuthContext, DomainContext } from "@syonet/gpd-hooks";

import "./Menu.css";
import { useStyles } from "./Menu.style";

/* eslint-disable-next-line */
export interface MenuProps {
  onKnowMore?: () => void;
  onHome?: () => void;
}

export function Menu(props: MenuProps) {
  const { signed, signedAndActive } = useContext(AuthContext);
  const { push } = useHistory();
  const { slug } = useContext(DomainContext);
  const classes = useStyles();

  const handleHome = () => {
    if (props.onHome !== undefined) {
      props.onHome();
    } else {
      push(`/${slug}`);
    }
  };

  const handleKnowMore = () => {
    if (props.onKnowMore !== undefined) {
      props.onKnowMore();
    } else {
      push(`/${slug}#saiba-mais`);
    }
  };

  const handleLoginButton = () => {
    if (signedAndActive) {
      push(`/${slug}/perfil`);
    } else if (signed) {
      push(`/${slug}/autenticacao-dupla`);
    } else {
      push(`/${slug}/login`);
    }
  };

  return (
    <header className="menu-root">
      <ul className="menu-options">
        <li>
          <Button onClick={handleHome}>
            <Typography className={classes.text}>Home</Typography>
          </Button>
        </li>
        <li>
          <Button onClick={handleKnowMore}>
            <Typography className={classes.text}>Saiba mais</Typography>
          </Button>
        </li>
        <li>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => handleLoginButton()}
          >
            <Typography className={classes.textButton} color="primary">
              {signedAndActive ? "Perfil" : "Sou Cadastrado"}
            </Typography>
          </Button>
        </li>
      </ul>
    </header>
  );
}

export default Menu;
