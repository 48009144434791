import {
  Box,
  Button, createStyles, makeStyles, Theme,
  Typography
} from "@material-ui/core";
import React from "react";

const useStyles2 = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      marginTop: "10px",
      justifyContent: "center",
      "@media screen and (max-width: 960px)": {
        height: "30px",
        padding: "10px 0",
      },
    },
    text: {
      fontSize: "10px",
      fontWeight: 700,
    },
    button: {
      width: "167px",
      height: "30px",
      fontFamily: "Roboto",
      fontSize: "13px",
      borderRadius: "60px",
      fontWeight: "bold",
      letterSpacing: "0.46px",
      lineHeight: "22px",
      padding: "4px 10px",
      "@media screen and (max-width: 600px)": {
        width: "150px",
        fontSize: "12px",
      },
    },
  })
);

export function FormActions(props: {
  cancel: () => void;
  confirm: () => void;
}) {
  const classes = useStyles2();
  return (
    <Box className={classes.buttonContainer} >
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={props.cancel}
      >
        <div
          color="textSecondary"
          className={classes.text}
        >
          VOLTAR
        </div>
      </Button>
      <Button
        className={classes.button}
        style={{ marginLeft: "14px" }}
        variant="contained"
        color="primary"
        onClick={props.confirm}
      >
        <Typography
          color="textPrimary"
          className={classes.text}
        >
          SALVAR
        </Typography>
      </Button>
    </Box>
  );
}

export default FormActions;