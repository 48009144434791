import { Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { ReactNode, useRef } from "react";

// necessário, pois o conteúdo dos parágrafos ativa um falso positivo sobre esse erro
/* eslint-disable no-irregular-whitespace */

// necessário, para o empacotamento da referência
/* eslint-disable react-hooks/rules-of-hooks */

export interface DataSection {
  id: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  ref: React.MutableRefObject<any>;
  label: string;
  position: number;
  content: () => ReactNode;
}
export interface SectionsCookiesPolicy {
  introdution: DataSection;
  whatIs: DataSection;
  whyWeUse: DataSection;
  whatTypes: DataSection;
  howToReject: DataSection;
  change: DataSection;
  contact: DataSection;
}

function Section(props: {
  children: ReactNode
}) {
  return (
    <Box pt={3} pb={3}>
      {props.children}
    </Box>
  );
}

function Subtitle(props: {
  children: ReactNode
}) {
  return (
    <Box p={1} >
      <Typography variant="h6" >
        {props.children}
      </Typography>
    </Box>
  );
}

function Paragraph(props: {
  children: ReactNode
}) {
  return (
    <Box p={1}>
      <Typography variant="body1" >
        {props.children}
      </Typography>
    </Box>
  );
}

function IntrodutionSectionContent() {
  return (
    <Section>
      <Paragraph>
        A nossa Política de COOKIES tem como principal
        propósito descrever as práticas que são admitidas,
        com a finalidade de respeitar a privacidade de todos
        os visitantes do nosso portal.
      </Paragraph>
      <Paragraph>
        A Lei Geral de Proteção de Dados, LGPD, descreve em
        seu artigo 5º, inciso I, que dado pessoal é todo
        aquele dentre o qual se refere a uma pessoa natural
        identificada ou identificável. Em exercício, dado
        pessoal ampara todo aquele dado que permite identificar
        uma pessoa diretamente ou a combinação de dados que
        podem identificar o titular de forma irrefutável.
      </Paragraph>
      <Paragraph>
        A utilização de COOKIES pelo Portal do Titular, tem
        como principal propósito manter o usuário logado na
        ferramenta durante a navegação.
      </Paragraph>
    </Section>
  );
}

function WhatIsSectionContent() {
  return (
    <Section>
      <Paragraph>
        Um cookie é uma tecnologia que nosso site solicita ao
        seu navegador para armazenar no seu computador ou outro
        dispositivo móvel, com objetivo de armazenar tokens de
        informação (identificador). Esse identificador é usado
        pelo Portal do Titular. A utilização dos COOKIES permite
        que o nosso site “lembre” das suas ações e preferências
        ao longo do tempo.
      </Paragraph>
      <Paragraph>
        Importante destacar que os COOKIES não são usados para
        determinar a identidade pessoal de quem está visitando
        nosso site.
      </Paragraph>
      <Paragraph>
        A maioria dos navegadores da Internet aceitam COOKIES
        automaticamente Entretanto, os usuários podem apagar
        os COOKIES a qualquer momento.
      </Paragraph>
    </Section>
  );
}

function WhyWeUseSectionContent() {
  return (
    <Section>
      <Paragraph>
        Usamos COOKIES tão somente para manter o usuário logado
        na plataforma durante a utilização do Portal do Titular.
      </Paragraph>
    </Section>
  );
}

function WhatTypesSectionContent() {
  return (
    <Section>
      <Subtitle>COOKIES da Sessão</Subtitle>
      <Paragraph>
        COOKIES da sessão são COOKIES temporários e
        utilizados para lembrar de você durante o curso da
        sua visita ao website, e eles perdem a validade
        quando você fecha o navegador.
      </Paragraph>
      <Subtitle>COOKIES Persistentes</Subtitle>
      <Paragraph>
        COOKIES persistentes são utilizados para lembrar
        suas preferências do website e permanecem no seu desktop
        ou dispositivo móvel mesmo depois de você fechar o seu
        navegador ou efetuar uma reinicialização. Se o usuário
        limpar o navegador, o COOKIE se perde e este deverá
        aceitá-lo novamente.
      </Paragraph>
    </Section>
  );
}

function HowToRejectSectionContent() {
  return (
    <Section>
      <Paragraph>
        Alertamos que os COOKIES que utilizamos para que o usuário
        se mantenha logado durante o uso da ferramenta é obrigatório
        para sua utilização; sendo que o mesmo não pode ser
        rejeitado, caso contrário o usuário não poderá utilizar
        o Portal do Titular.
      </Paragraph>
    </Section>
  );
}

function ChangeSectionContent() {
  return (
    <Section>
      <Paragraph>
        A presente Política de COOKIES foi atualizada pela última
        vez em 16/08/2021.
      </Paragraph>
      <Paragraph>
        A Política de COOKIES sempre que necessário poderá ser alterada,
        por isso convidamos você a visitar nossas políticas frequentemente.
      </Paragraph>
      <Paragraph>
        As alterações e esclarecimentos vão surtir efeito imediatamente
        após a sua publicação no Portal do Titular.
      </Paragraph>
    </Section>
  );
}

function ContactSectionContent() {
  return (
    <Section>
      <Paragraph>
        Se persistirem dúvidas sobre a nossa Política de COOKIES, estamos
        disponíveis através dos contatos descritos no rodapé da página
        inicial (Home) de nosso site.
      </Paragraph>
    </Section>
  );
}

export function generateCookiesPolicySections() {
  const sections: DataSection[] = [
    {
      id: "introducao",
      ref: useRef(null),
      label: "Introdução",
      position: 1,
      content: IntrodutionSectionContent
    },
    {
      id: "o-que-e",
      ref: useRef(null),
      label: "O que é um cookie?",
      position: 2,
      content: WhatIsSectionContent
    },
    {
      id: "porque-usamos",
      ref: useRef(null),
      label: "Por que usamos cookies?",
      position: 3,
      content: WhyWeUseSectionContent
    },
    {
      id: "quais-tipos",
      ref: useRef(null),
      label: "Quais os tipos de cookies que utilizamos?",
      position: 4,
      content: WhatTypesSectionContent
    },
    {
      id: "como-rejeitar",
      ref: useRef(null),
      label: "Como rejeitar ou apagar os cookies?",
      position: 5,
      content: HowToRejectSectionContent
    },
    {
      id: "alteracao",
      ref: useRef(null),
      label: "Alteração da política de cookies",
      position: 6,
      content: ChangeSectionContent
    },
    {
      id: "contato",
      ref: useRef(null),
      label: "Informações de contato",
      position: 7,
      content: ContactSectionContent
    }
  ];
  return sections.sort((a, b) => a.position - b.position);
}
