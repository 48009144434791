import React, { useContext, useState } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  SvgIcon,
  Theme,
  Typography,
  Container,
} from "@material-ui/core";
import { Copyright } from "@portal-do-titular/ui-components";
import { useStyles } from "./MessageBlock.style";
import { DomainContext, useApi } from "@syonet/gpd-hooks";

import defaultLogo from "../../assets/logo2.svg";
import { MenuSectionLogo } from "@portal-do-titular/ui-components";

export interface MessageBlockProps {
  title: string;
  message: string;
}

const useStyles2 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      borderRadius: "0px 6% 6% 0px",
      padding: "5% 5% 5% 100px",
      display: "Grid",
      gridTemplateRows: "auto 90% auto",
      zIndex: 1,
    },
    title: {
      [theme.breakpoints.down("lg")]: {
        fontSize: "2.25rem",
      },
    },
    logo: {
      display: "flex",
      alignItems: "center",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    main: {
      backgroundColor: theme.palette.secondary.main,
    },
    copyright: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      alignContent: "space-evenly",
    },
    copyrightText: {
      marginLeft: "17px",
    },
    textColor: {
      color: theme.palette.secondary.contrastText,
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important",
        marginTop: "30px",
      },
    },
  })
);

export function MessageBlock({
  title,
  message,
}: MessageBlockProps): JSX.Element {
  const { account } = useContext(DomainContext);
  const { resolveLogoPath } = useApi();
  const [logo, setLogo] = useState(null);

  React.useEffect(() => {
    if (!account) return;
    const logoURL = resolveLogoPath(account);
    setLogo(logoURL ?? defaultLogo);
  }, [account]);

  const classes = useStyles();
  return (
    <Box className={classes.messageBlockContent}>
      <Box>
        <Typography variant="h5" className={classes.text}>
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" className={classes.text}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
}

export default MessageBlock;
