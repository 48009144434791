import React, { useContext } from "react";
import { Box, Typography, Link, Hidden } from "@material-ui/core";
import { useFooterStyles } from "./Footer.style";
import Copyright from "./copyright/Copyright";
import { MenuSectionLogo } from "@portal-do-titular/ui-components";
import { DomainContext, useApi } from "@syonet/gpd-hooks";
import WarningDialog from "../modals/WarningDialog";

/* eslint-disable-next-line */
export interface FooterCompProps { }

export function Footer(props: FooterCompProps) {
  const classes = useFooterStyles();
  const { account } = useContext(DomainContext);
  const [openDialog, setOpenErrorDialog] = React.useState(false);

  const handleError = () => setOpenErrorDialog(true);

  function CheckPrivacyPolicy() {
    console.log(account.pathPrivacyPolicy);
    return !account.pathPrivacyPolicy ? (
      <Link className={classes.text} component="button" onClick={handleError}>
        Política de Privacidade
      </Link>
    ) : (
      <Typography
        className={classes.text}
        component="a"
        href={useApi().resolvePrivacyPolicyPath(account)}
      >
        Política de Privacidade
      </Typography>
    );
  }
  function FooterBaseElement(props: {
    header: JSX.Element,
    content: JSX.Element,
    lastElement?: boolean
  }) {
    const classNameSelected = props.lastElement
      ? classes.mobileBoxTwo
      : classes.mobileBoxOne;
    return (
      <Box className={classNameSelected}>
        {props.header}
        {props.content}
      </Box>
    );
  }
  function PrivacyPolicyField() {
    return (
      <FooterBaseElement
        header={<CheckPrivacyPolicy />}
        content={<Typography className={classes.textDPO}>{" "}</Typography>}
      />
    );
  }
  function FooterElement(props: {
    title: string,
    value: string,
    lastElement?: boolean
  }) {
    if (!props.value) {
      const classNameSelected = props.lastElement
        ? classes.mobileBoxTwo
        : classes.mobileBoxOne;
      return <Box className={classNameSelected}></Box>;
    }
    return (
      <FooterBaseElement
        header={<Typography className={classes.text}>{props.title}</Typography>}
        content={<Typography className={classes.textDPO}>{props.value}</Typography>}
        lastElement={props.lastElement}
      />
    );
  }

  return (
    <Box className={classes.container}>
      <WarningDialog
        message="O arquivo de politica de privacidade não foi localizado"
        title="Oops!"
        state={[openDialog, setOpenErrorDialog]}
      />
      <Box className={classes.upContainer}>
        <MenuSectionLogo
          imgSize={classes.imgSize}
          boxProps={{
            color: classes.textColor,
            className: "",
          }}
        />
        <Box className={classes.contactInfoContainer}>
          <PrivacyPolicyField />
          <FooterElement
            title="Telefone de contato"
            value={account?.phoneDPO}
          />
          <FooterElement
            title="E-mail de contato"
            value={account?.emailDPO}
            lastElement
          />
        </Box>
      </Box>
      <Box className={classes.copyrigthContainer}>
        <Copyright />
      </Box>
    </Box>
  );
}

export default Footer;
