import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useFooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "225px",
      width: "100vw",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down("xs")]: {
        height: "370px",
      },
    },
    contactInfoContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1),
        marginLeft: theme.spacing(3),
        marginRight: "auto",
      },
    },
    copyrigthContainer: {
      width: "1070px",
      height: "89px",
      margin: "0 auto",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        height: "157px",
        alignItems: "center",
      },
    },
    imgSize: {
      width: "80px",
      height: "80px",
      [theme.breakpoints.down("xs")]: {
        marginTop: "30px",
        marginRight: "29px",
      },
    },
    upContainer: {
      width: "1070px",
      height: "136px",
      display: "grid",
      alignItems: "center",
      margin: "0 auto",
      gridTemplateColumns: "227px 614px",
      columnGap: "180px",
      borderBottom: `1px solid ${theme.palette.secondary.contrastText}`,
      [theme.breakpoints.down("md")]: {
        width: "calc(90vw - 10px)",
        height: "136px",
        gridTemplateColumns: "170px 500px",
        columnGap: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "calc(90vw - 10px)",
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
    },
    text: {
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: "12px",
      color: theme.palette.secondary.contrastText,
    },
    textDPO: {
      fontFamily: "Montserrat",
      fontWeight: 700,
      fontSize: "14px",
      minHeight: "21px",
      color: theme.palette.secondary.contrastText,
    },
    textColor: {
      color: theme.palette.secondary.contrastText,
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important",
        marginTop: "30px",
      },
    },
    mobileBoxOne: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "26px",
      },
    },
    mobileBoxTwo: {
      [theme.breakpoints.down("xs")]: {
        paddingBottom: "56px",
      },
      [theme.breakpoints.down("md")]: {
        paddingBottom: "26px",
      },
    },
  })
);

export { useFooterStyles };
