import {
  Box,
  Container,
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
  Typography
} from "@material-ui/core";
import {
  ConfirmDialog,
  LoginForm,
  MessageBlock,
  PageContent,
  ResetPasswordDTO,
  StepFourFrom,
  StepOneFrom,
  StepTreeFrom,
  StepTwoFrom,
  WarningDialog
} from "@portal-do-titular/ui-components";
import {
  AuthContext,
  DomainContext,
  Person,
  useApi,
  useAuthCode,
  useHiddenMask,
  useResetPassword,
  VerifyCodeData
} from "@syonet/gpd-hooks";
import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import ColumnsLayout from "../layout/ColumnsLayout";
import "./Login.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorprimary: {
      color: "#1E1E1E",
    },
    rightContainer: {
      [theme.breakpoints.up("md")]: {
        paddingRight: "168.5px",
      },
    },
  })
);

function PasswordRedefinationSuccessDialog(props: {
  open: boolean;
  onClose: (v: boolean) => void;
}) {
  const title = "Olá";
  const message = "Sua senha foi redefinida com sucesso!"
    + " Clique no botão abaixo para ser redirecionado para a tela de login.";
  const textConfirm = "OK";
  return (
    <ConfirmDialog
      title={title}
      message={message}
      hasButton
      labelButton={textConfirm}
      handleClickButton={() => console.log(textConfirm)}
      state={[props.open, props.onClose]}
    />
  );
}

function InvalidCodeDialog(props: {
  open: boolean;
  onClose: (v: boolean) => void;
}) {
  const title = "Olá";
  const message = "Seu código esta inválido. Por favor, tente novamente!";
  return (
    <WarningDialog
      title={title}
      message={message}
      state={[props.open, props.onClose]}
    />
  );
}

function NotFoundPersonRecordDialog(props: {
  open: boolean;
  onClose: (v: boolean) => void;
}) {
  const title = "Olá";
  const message = "Não foi encontrado seu cadastro. Por favor, tente novamente!";
  return (
    <WarningDialog
      title={title}
      message={message}
      state={[props.open, props.onClose]}
    />
  );
}

function FailedSendCodeDialog(props: {
  open: boolean;
  onClose: (v: boolean) => void;
}) {
  const title = "Olá";
  const message = "Não foi possível lhe enviar o código."
    + " Por favor, se possível tente outro meio ou tente mais tarde!";
  return (
    <WarningDialog
      title={title}
      message={message}
      state={[props.open, props.onClose]}
    />
  );
}

function InvalidRedefinationPasswordDialog(props: {
  open: boolean;
  onClose: (v: boolean) => void;
}) {
  const title = "Olá";
  const message = "Não foi possível alterar sua senha, pois as senhas não correspondem."
    + " Por favor, tente novamente!";
  return (
    <WarningDialog
      title={title}
      message={message}
      state={[props.open, props.onClose]}
    />
  );
}

function FailedAuthenticationDialog(props: {
  open: boolean;
  onClose: (v: boolean) => void;
}) {
  const title = "Olá";
  const message = "Sua senha ou email estão incorretos."
    + " Você pode tentar novamente ou redefenir a senha.";
  return (
    <WarningDialog
      title={title}
      message={message}
      state={[props.open, props.onClose]}
    />
  );
}

function SendCodeDialog(props: {
  open: boolean;
  onClose: (v: boolean) => void;
}) {
  const title = "Olá";
  const message = "Foi enviado um código de 4 dígitos, assim que receber preencha o campo abaixo.";
  const textConfirm = "OK";
  return (
    <ConfirmDialog
      title={title}
      message={message}
      hasButton
      labelButton={textConfirm}
      handleClickButton={() => console.log(textConfirm)}
      state={[props.open, props.onClose]}
    />
  );
}

function AuthenticationForm(props: {
  slug: string;
  basePath: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onSubmit: (v: any) => void;
  loading: boolean;
}) {
  const title = "Login";
  return (
    <PageContent>
      <Container id="login-content">
        <Box>
          <Box p={1} textAlign="center">
            <Typography id="login-title" variant="h5">
              {title}
            </Typography>
          </Box>
          <LoginForm
            links={{
              link1: `${props.slug}/`,
              link2: `${props.basePath}/encontrar-conta`,
            }}
            onSubmit={props.onSubmit}
          />
          {props.loading && <LinearProgress />}
        </Box>
      </Container>
    </PageContent>
  );
}

function RedefinationPasswordForm(props: {
  step: number;
  initilizeStep: () => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onSubmitStep1: (v: any) => void;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onSubmitStep2: (v: any) => void;
}) {
  const classes = useStyles();
  const disabled = "disabled";
  const abled = "";
  const [disableClass, setDisableClass] = useState(Array(4).fill(disabled));
  useEffect(() => props.initilizeStep(), []);
  useEffect(() => {
    const newState = [...disableClass];
    newState[props.step] = abled;
    if (props.step > 0) {
      newState[props.step - 1] = disabled;
    }
    setDisableClass(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.step]);
  const message = "Esqueceu sua senha?";
  return (
    <Box id="login-content">
      <Box mb={2} >
        <Typography
          id="login-password-forgot-title"
          variant="h5"
          className={classes.colorprimary}
        >
          {message}
        </Typography>
      </Box>
      <Box className={`login-step ${disableClass[0]}`}>
        <StepOneFrom
          onSubmit={props.onSubmitStep1}
          step={1}
        />
      </Box>
      <Box className={`login-step ${disableClass[1]}`}>
        <StepTwoFrom step={2} onSubmit={props.onSubmitStep2} />
      </Box>
    </Box>
  );
}

function VerifyCode(props: {
  step: number;
  onVerifyCode: (v: VerifyCodeData) => void;
  onPasswordChange: (v: ResetPasswordDTO) => void;
}) {
  const classes = useStyles();
  const disabled = "disabled";
  const abled = "";
  const [disableClass, setDisableClass] = useState(Array(4).fill(disabled));
  useEffect(() => {
    const newState = [...disableClass];
    newState[props.step] = abled;
    if (props.step > 0) {
      newState[props.step - 1] = disabled;
    }
    setDisableClass(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.step]);
  const title = "Esqueceu sua senha?";
  return (
    <Container id="login-content">
      <Box mb={2} >
        <Typography
          id="login-password-forgot-title"
          variant="h5"
          className={classes.colorprimary}
        >
          {title}
        </Typography>
      </Box>
      <Box className={`login-step ${disableClass[2]}`}>
        <StepTreeFrom step={3} onSubmit={props.onVerifyCode} />
      </Box>
      <Box className={`login-step ${disableClass[3]}`}>
        <StepFourFrom step={4} onSubmit={props.onPasswordChange} />
      </Box>
    </Container>
  );
}

function DataSection() {
  const { path: pathWithoutDomain } = useRouteMatch();
  const { slug, injectSlug } = useContext(DomainContext);
  const path = injectSlug(pathWithoutDomain);
  const { push } = useHistory();
  const { signIn, signed, person } = useContext(AuthContext);
  const { api } = useApi();
  // dialogs
  const [openPasswordRedefinationSuccessDialog, setOpenPasswordRedefinationSuccessDialog] = useState(false);
  const [openFailedAuthenticationDialog, setOpenFailedAuthenticationDialog] = useState(false);
  const [openInvalidCodeDialog, setOpenInvalidCodeDialog] = useState(false);
  const [openNotFoundPersonRecordDialog, setOpenNotFoundPersonRecordDialog] = useState(false);
  const [openFailedSendCodeDialog, setOpenFailedSendCodeDialog] = useState(false);
  const [openInvalidRedefinationPasswordDialog, setOpenInvalidRedefinationPasswordDialog] = useState(false);
  const [openSendCodeDialog, setOpenSendCodeDialog] = useState(false);
  // loading
  const [sendingAuthentication, setSendingAuthentication] = useState(false);
  // redefination password
  const { hiddenEmail, hiddenTelephone } = useHiddenMask();
  const { sendConfirmCode, validate } = useAuthCode();
  const { resetPassword } = useResetPassword();
  const [redefinationPasswordStep, setRedefinationPasswordStep] = useState(0);
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const sendCodeToPerson = (param: any) => {
    const input = param["email-telefone"];
    // eslint-disable-next-line
    const regexValidEmail = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    let email: string;
    let ddd: string;
    let telephone: string;
    if (regexValidEmail.test(("" + input).toLowerCase())) {
      email = input;
    } else {
      const cleanPhone = input.match(/\d+/g).join("");
      ddd = cleanPhone.substring(0, 2);
      telephone = cleanPhone.substring(2);
    }
    api
      .get("/v1/person", { params: { email, telephone, ddd } })
      .then(({ data }): Person[] => {
        if (Array.isArray(data) && data.length > 0) {
          console.log(data[0]);
          const state = {
            telephone: hiddenTelephone(data[0].dddTelephone, data[0].telephone),
            email: hiddenEmail(data[0].crmEmail),
            person: data[0],
          };
          push(`${path}/encontrar-conta`, state);
          setRedefinationPasswordStep(redefinationPasswordStep + 1);
          return;
        }
        setOpenNotFoundPersonRecordDialog(true);
      })
      .catch((error) => {
        console.log(error);
        if (404 === error.response.status) {
          setOpenNotFoundPersonRecordDialog(true);
        }
      });
  };
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const validateSentCode = async (param: any) => {
    try {
      await sendConfirmCode(param);
      setOpenSendCodeDialog(true);
      push(`${path}/encontrar-conta/confirmar-codigo`, param);
      setRedefinationPasswordStep(redefinationPasswordStep + 1);
    } catch (e) {
      setOpenFailedSendCodeDialog(true);
    }
  };
  const onVerifyCode = async (param: VerifyCodeData) => {
    const { status } = await validate(param);
    if (status === 1) {
      setOpenInvalidCodeDialog(true);
      return;
    }
    push(`${path}/encontrar-conta/confirmar-codigo`, param);
    setRedefinationPasswordStep(redefinationPasswordStep + 1);
  };
  const onPasswordChange = async (param: ResetPasswordDTO) => {
    const { passwordOne, passwordTwo, code, idPerson } = param;
    if (passwordOne !== passwordTwo) {
      setOpenInvalidRedefinationPasswordDialog(true);
      return;
    }
    const { data } = await resetPassword({ idPerson, code, password: passwordOne });
    if (data.status === 1) {
      return;
    }
    setOpenPasswordRedefinationSuccessDialog(true);
  };

  const onClosePasswordRedefinationSuccessDialog = (value: boolean) => {
    setOpenPasswordRedefinationSuccessDialog(value);
    push(`/${slug}/login`);
  };
  const onSubmitAuthentication = async (param) => {
    setSendingAuthentication(true);
    try {
      await signIn(param);
      setSendingAuthentication(false);
    } catch (e) {
      console.log(e);
      setSendingAuthentication(false);
      setOpenFailedAuthenticationDialog(true)
    }
  };
  return (
    <>
      <PasswordRedefinationSuccessDialog
        open={openPasswordRedefinationSuccessDialog}
        onClose={onClosePasswordRedefinationSuccessDialog}
      />
      <InvalidRedefinationPasswordDialog
        open={openInvalidRedefinationPasswordDialog}
        onClose={setOpenInvalidRedefinationPasswordDialog}
      />
      <FailedAuthenticationDialog
        open={openFailedAuthenticationDialog}
        onClose={setOpenFailedAuthenticationDialog}
      />

      <NotFoundPersonRecordDialog
        open={openNotFoundPersonRecordDialog}
        onClose={setOpenNotFoundPersonRecordDialog}
      />

      <SendCodeDialog
        open={openSendCodeDialog}
        onClose={setOpenSendCodeDialog}
      />
      <FailedSendCodeDialog
        open={openFailedSendCodeDialog}
        onClose={setOpenFailedSendCodeDialog}
      />
      <InvalidCodeDialog
        open={openInvalidCodeDialog}
        onClose={setOpenInvalidCodeDialog}
      />

      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => <AuthenticationForm
            onSubmit={onSubmitAuthentication}
            loading={sendingAuthentication}
            slug={slug}
            basePath={path}
          />}
        />
        <Route
          exact
          path={`${path}/encontrar-conta`}
          render={() => <RedefinationPasswordForm
            step={redefinationPasswordStep}
            initilizeStep={() => setRedefinationPasswordStep(0)}
            onSubmitStep1={sendCodeToPerson}
            onSubmitStep2={validateSentCode}
          />}
        />
        <Route
          exact
          path={`${path}/encontrar-conta/confirmar-codigo/`}
          render={() => <VerifyCode
            step={redefinationPasswordStep}
            onVerifyCode={onVerifyCode}
            onPasswordChange={onPasswordChange}
          />}
        />
      </Switch>
    </>
  );
}

function WelcomeToSystem() {
  const title = "Seja muito bem-vindo (a) ao Portal do Titular.";
  const message = "É um prazer receber você em nosso portal."
    + " Estamos muito felizes em, juntamente com você, proteger seus dados.";
  return (
    <MessageBlock
      title={title}
      message={message}
    />
  );
}

function Login() {
  return (
    <ColumnsLayout
      data={WelcomeToSystem}
    >
      <DataSection />
    </ColumnsLayout>
  );
}

export default Login;
