import React from 'react'
import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import './PageNotFound.css'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default
    },
    text: {
      color: 'rgba(0,0,0,0.54)'
    }

  }))
export const PageNotFound = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}>
        <Grid className={classes.text}>
          <Typography variant="h5" paragraph align="left">
            {"Eita!!!"}
          </Typography>
          <Typography paragraph align="left">
            {"Acho que tem algo errado com seu acesso."}
          </Typography>
          <Typography paragraph align="left">
            {"Se certifique que a URL está correta, caso esteja, tente novamente mais tarde."}
          </Typography>
        </Grid>
        <img src="./assets/404.jpg" alt="Página não encontrada" />
      </Grid>
    </Container >
  )
}

export default PageNotFound