/* eslint-disable jsx-a11y/alt-text */
import React, { ReactNode } from "react";
import {
  Box,
  CardMedia,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { useBannerStyles } from "./Banner.style";
import { prependOnceListener } from "process";

export interface BannerProps {
  title: string;
  description?: string;
  text?: string;
  subText?: string;
  img: string;
  children?: ReactNode;
}

function Paragraph(props: {
  children: ReactNode
}) {
  const classes = useBannerStyles();
  return (
    <Typography variant="body1" >
      <Box className={classes.bannerTypographyDescription}>
        {props.children}
      </Box>
    </Typography>
  );
}

export function Banner(props: BannerProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useBannerStyles();
  const textParagraph: string[] = [];
  if (props.description) {
    props.description.split("\\n").forEach(t => textParagraph.push(t));
  }
  if (props.text) {
    props.text.split("\\n").forEach(t => textParagraph.push(t));
  }
  if (props.subText) {
    props.subText.split("\\n").forEach(t => textParagraph.push(t));
  }
  return (
    <Box my={2}>
      <Grid container className={classes.bannerBackground}>
        <Grid item md={7} xs={12}>
          <Box className={classes.bannerPadding}>
            <Box>
              <Typography variant="h6">
                <Box className={classes.bannerTypographyTitle} pb={3}>
                  {props.title}
                </Box>
              </Typography>
            </Box>
            {textParagraph.map(text => <Paragraph>{text}</Paragraph>)}
            {!!props.children && (<Paragraph>{props.children}</Paragraph>)}
          </Box>
        </Grid>
        {matches ? (
          <React.Fragment />
        ) : (
          <Grid item xs={6} lg={2} sm={3}>
            <CardMedia component="img" image={props.img} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Banner;
