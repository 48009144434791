import React, { createContext, useState } from "react";

export interface DomainContextData {
  slug: string;
  domain: string;
  injectSlug: (path: string) => string;
  account: Account;
  setAccount: (account: Account) => void;
}

export interface Account {
  id?: number;
  name?: string;
  urlPortal?: string;
  urlCRM?: string;
  domain?: string;
  slug?: string;
  enterpriseID?: number;
  emailDPO?: string;
  phoneDPO?: string;
  homeText?: string;
  finalityDescription?: string;
  primaryColor?: string;
  secondaryColor?: string;
  eventGroup?: string;
  eventType?: string;
  eventOrigin?: string;
  pathLogo?: string;
  pathPrivacyPolicy?: string;
  status?: boolean;
  contactDialogText?: string;
  subjectEmail?: string;
  textEmail?: string;
  textSms?: string;
  menus?: string[];
  manageLegitInterest?: boolean;
}

export const DomainContext = createContext<DomainContextData>(
  {} as DomainContextData
);

export const DomainProvider = ({ children }) => {
  const [slug, setSlug] = useState("");
  const [domain, setDomain] = useState("");
  const [account, setAccountState] = useState<Account>(null);

  const injectSlug = (path: string) => path.replace(":slug", slug);

  const setAccount = (account: Account) => {
    setAccountState(account);
    setDomain(account.domain);
    setSlug(account.slug);
  };

  return (
    <DomainContext.Provider
      value={{
        domain,
        slug,
        account,
        injectSlug,
        setAccount,
      }}
    >
      {children}
    </DomainContext.Provider>
  );
};
