import React, { useContext, useEffect, useRef } from "react";
import "./Home.css";
import { Button, Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { Account, DomainContext } from "@syonet/gpd-hooks";
import { KnowMore, BannerLgpd } from "@portal-do-titular/ui-components";
import { handleHashOnPathname, scrollToRef } from "@portal-do-titular/utils";
import MainBackGroundImg from "../../../assets/Home.svg";
import { useHomeStyles } from "./Home.style";
import OneColumnLayout from "../layout/OneColumnLayout";

interface DataSection {
  id: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  ref: React.MutableRefObject<any>;
}

interface SectionsHome {
  home: DataSection;
  knowMore: DataSection;
}

function ImportantTag() {
  return <b>Importante</b>
}

function AboutSection(props: {
  account: Account;
  goToCreateUser: () => void;
}) {
  const classes = useHomeStyles();
  const title = "PORTAL DO TITULAR";
  const subtitle = "FINALIDADE DE AUTORIZAÇÃO";
  const defaultHomeText = "Este portal está em conformidade"
    + " com a LGPD e seu objetivo é facilitar o acesso às"
    + " informações pelo cliente final (titular dos dados)"
    + " e principalmente proteger ao concessionário no que"
    + " tange as penalidades prevista na LGPD. Este portal"
    + " explica para o titular dos dados todos os processos"
    + " e finalidades que o concessionário criou para atender"
    + " a LGPD e cria controles, logs, opt-in e opt-out para o"
    + " concessionário conseguir seguir seu processo. Para isso"
    + " é necessário que você se cadastre no portal e siga as"
    + " instruções que o mesmo lhe oferecerá.";
  const homeText = !!props.account && !!props.account.homeText
    ? props.account.homeText
    : defaultHomeText;
  const labelButtonCreateRecord = "CADASTRAR";
  const highlightInformation = "Em todas as telas teremos explicações sobre cada funcionalidade.";
  return (
    <Box className={clsx(classes.homeMainBackground, classes.content)} >
      <Box className="home-main-container">
        <Box className={`home-main-container-with-img`}>
          <Box className="home-text-container">
            <Typography
              id="home-titulo"
              className={classes.homeTextSecondaryColor}
            >
              {title}
            </Typography>
            <Typography
              id="home-subtitulo"
              className={classes.homeTextSecondaryColor}
            >
              {subtitle}
            </Typography>
            <Box>
              <Typography
                id="home-span-text"
                className={classes.homeTextSecondaryColor}
              >
                {homeText}
              </Typography>
            </Box>
            <Box className="home-button-container">
              <Button
                id="home-botao"
                variant="contained"
                color="primary"
                onClick={() => props.goToCreateUser()}
              >
                {labelButtonCreateRecord}
              </Button>
            </Box>
          </Box>
          <img
            className="home-background-image"
            src={MainBackGroundImg}
            alt="Homem utilizando compuntador"
          />
        </Box>
        <Box className={classes.homeTextExplanationContainer}>
          <Typography
            className={classes.homeTextExplanation}
            color="primary"
          >
            <ImportantTag />
            <b>:</b> {highlightInformation}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function BannerLgpdSection() {
  const dataInfo = "Utilizamos cookies essenciais e tecnologias"
    + " semelhantes de acordo com a nossa Política de Cookies"
    + " e, ao continuar navegando, você concorda com estas condições.";
  return (
    <BannerLgpd text={dataInfo} />
  );
}


function Home() {
  const sections: SectionsHome = {
    home: {
      id: "home",
      ref: useRef(null)
    },
    knowMore: {
      id: "saiba-mais",
      ref: useRef(null)
    }
  };
  const { push } = useHistory();
  const { slug, account } = useContext(DomainContext);
  const handleSection = (section: DataSection) => {
    handleHashOnPathname(section.id);
    scrollToRef(section.ref);
  };
  useEffect(() => {
    const hash = document.location.hash || "";
    const goToKnowMore = hash === `#${sections.knowMore.id}`;
    if (goToKnowMore) {
      handleSection(sections.knowMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OneColumnLayout
      onHome={() => handleSection(sections.home)}
      onKnowMore={() => handleSection(sections.knowMore)}
    >
      <div ref={sections.home.ref} id={sections.home.id} >
        <AboutSection
          account={account}
          goToCreateUser={() => push(`/${slug}/cadastro`)}
        />
      </div>
      <div ref={sections.knowMore.ref} id={sections.knowMore.id} >
        <KnowMore />
      </div>
      <BannerLgpdSection />
    </OneColumnLayout>
  );
}

export default Home;
