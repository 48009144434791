import { InputAdornment, Container, Box, Typography } from "@material-ui/core";
import React from "react";
import "../menu-column-form/MenuColumnForm.css";
import MenuColumnForm from "../menu-column-form/MenuColumnForm";
import myProcess from "../../../assets/myProcess.svg";

export interface MyProcessDTO {
  label: string;
}

export interface MyProcessProps {
  onSubmit: (param: MyProcessDTO) => void;
}

export function MyProcess({ onSubmit }: MyProcessProps) {
  const submitHandler = (data: MyProcessDTO) => {
    onSubmit(data);
  };

  const classCenter = "Menu-column-class-center";

  return (
    <MenuColumnForm button={{ onSubmit: submitHandler }}>
      <Container>
        <Box margin="5px">
          <div id="Menu-column-icon" className={classCenter}>
            <img src={myProcess} />
          </div>
          <div id="Menu-column-title" className={classCenter}>
            Meus Processos
          </div>
        </Box>
      </Container>
    </MenuColumnForm>
  );
}

export default MyProcess;
