import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "600px",
      maxHeight: "800px",
    },
    space: {
      paddingRight: "8px",
    },
    field: {
      marginLeft: "10px",
      fontWeight: 600,
    },
    disabled: {
      opacity: 0.5,
    },
  })
);

export { useStyles };
