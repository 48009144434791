import React, { useEffect, useState } from "react";
import useApi from "./useApi";
import { Person } from "./usePerson";

export interface ResetData {
  idPerson: number;
  code: string;
  password: string;
}

export interface ResetOutput {
  status: number;
  message: string;
}

export const useResetPassword = () => {
  const { api, handleRequest } = useApi();

  const resetPassword = async (resetData: ResetData) => {
    const { data, status } = await handleRequest(() =>
      api.post("v1/login/resetPassword", resetData)
    );

    return { data, status } as { data: ResetOutput; status: number };
  };

  return { resetPassword };
};
