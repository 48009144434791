import { Button, Typography } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import React, { useContext } from "react";

import { AuthContext, DomainContext } from "@syonet/gpd-hooks";

import "./Menu.css";
import { useStyles } from "./Menu.style";

export interface MenuKnowMoreProps {
  hasLogout?: boolean
}

export function MenuKnowMore(props: MenuKnowMoreProps) {
  const classes = useStyles();
  const { signed, signedAndActive } = useContext(AuthContext);
  const { slug } = useContext(DomainContext);
  const { logout } = useContext(AuthContext);
  const { push } = useHistory();
  const handleKnowMore = () => push(`/${slug}#saiba-mais`);
  const handleHome = () => push(`/${slug}/`);
  const handleLogout = () => logout();
  const handleLogin = () => {
    if (signedAndActive) {
      push(`/${slug}/perfil`);
    } else if (signed) {
      push(`/${slug}/autenticacao-dupla`);
    } else {
      push(`/${slug}/login`);
    }
  };

  return (
    <header className="menu-root">
      <ul className="menu-options">
        <li>
          <Button onClick={handleHome}>
            <Typography className={classes.text}>Home</Typography>
          </Button>
        </li>
        <li>
          <Button onClick={handleKnowMore}>
            <Typography className={classes.text}>Saiba mais</Typography>
          </Button>
        </li>
        {
          props.hasLogout &&
          <li>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={handleLogout}
            >
              <Typography className={classes.textButton} color="primary">
                Sair
              </Typography>
            </Button>
          </li>
        }
        {
          !props.hasLogout &&
          <li>
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={handleLogin}
            >
              <Typography className={classes.textButton} color="primary">
                {signedAndActive ? "Perfil" : "Sou Cadastrado"}
              </Typography>
            </Button>
          </li>
        }
      </ul>
    </header>
    /*
    Bkp do campo de busca:
    <Box className="menu-know-more">
      <FormControl fullWidth className="menu-search-form">
        <TextField
          style={{ maxWidth: "600px" }}
          margin="dense"
          id="menu-search-field"
          variant="outlined"
          placeholder="Buscar"
          InputProps={{
            style: { background: "white" },
            startAdornment: (
              <InputAdornment position="start">
                <img src={SearchIcon} alt="magnifying glass icon" />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>      
    </Box>*/
  );
}

export default MenuKnowMore;
