import React from "react";
import "../menu-column-form/MenuColumnForm.css";
import MenuColumnForm from "../menu-column-form/MenuColumnForm";
import dataContest from "../../../assets/dataContest.svg";
import { useStyles } from "../menu-column-form/MenuColumnForm";

export interface DataContestDTO {
  label: string;
}

export interface DataContestProps {
  onSubmit: (param: DataContestDTO) => void;
}

export function DataContest({ onSubmit }: DataContestProps) {
  const classes = useStyles();
  const submitHandler = (data: DataContestDTO) => {
    onSubmit(data);
  };

  return (
    <MenuColumnForm button={{ onSubmit: submitHandler }}>
      <img id="menu-column-icon" src={dataContest} />
      <div id="menu-column-title" className={classes.secondaryColor}>
        Contestação de dados
      </div>
    </MenuColumnForm>
  );
}

export default DataContest;
