import { Box, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";

import "./AuthTitle.css";

/* eslint-disable-next-line */
export interface AuthTitleProps {
  title: string;
  description: string;
  image: string;
}

export function AuthTitle(props: AuthTitleProps) {
  const { title, description, image } = props;

  return (
    <Grid
      className="auth-container"
      container
      alignItems="center"
      justify="space-around"
    >
      <Grid item>
        <Box className="auth-text-content">
          <Box>
            <Typography variant="h1" id="auth-title">
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h3" id="auth-description">
              {description}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <div id="auth-card-image">
          <img src={image} alt="imagem escritorio" height="250px" />
        </div>
      </Grid>
    </Grid>
  );
}

export default AuthTitle;
