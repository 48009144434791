import { useContext } from "react";
import { DomainContext } from "./DomainContext";
import useApi from "./useApi";

export interface ProcessData {
  processID: number;
  processName: string;
}

export interface ContactPreferencesData {
  preferences: PreferencesData[];
}

interface ContactPreferencesDataSend {
  preferences: PreferencesData[];
  justification: string;
}

export interface PreferencesData {
  crmID: number;
  finalityID: number;
  description: string;
  consent: boolean;
  preferenceSms: boolean;
  preferenceEmail: boolean;
  preferenceTelephone: boolean;
  preferenceWhatsapp: boolean;
}

export interface FinalitiesPreferencesData {
  finalityID: number;
  description: string;
  showPortal: boolean;
  process: ProcessData[];
  legalBases: string[];
  contactPreference: PreferencesData;
}

export interface Finality {
  finalityID: number;
  description: string;
  contactPreference: PreferencesData;
}

export interface GroupByProcess {
  processID: number;
  processName: string;
  crmID: number;
  finalities: Finality[];
}

export const useConsent = () => {
  const { api, handleRequest } = useApi();
  const { account } = useContext(DomainContext);

  const get = async (crmID: number) => {
    const { data, status } = await handleRequest(() =>
      api.get(`v1/auth/finality/${crmID}`)
    );
    return { data, status } as {
      data: FinalitiesPreferencesData[];
      status: number;
    };
  };

  const getFinalities = async (crmId: number) => {
    const { data, status } = await get(crmId);
    if (status !== 200) {
      return null;
    }
    const finalities: FinalitiesPreferencesData[] = data
      .filter((finality) => { return(verificaFinaidades(finality)); });

    return finalities;
  };

  const getGroupedFinalities = (
    finalities: FinalitiesPreferencesData[],
    crmID: number
  ) => {
    if (finalities.length === 0 || !finalities) {
      return null;
    }

    return handleGroupByProcess(finalities, crmID);
  };

  const getPreferences = (
    finalities: FinalitiesPreferencesData[],
    crmId: number
  ) => {
    if (finalities.length === 0 || !finalities) {
      return null;
    }

    return handlePreferences(finalities, crmId);
  };

  const handlePreferences = (
    finalities: FinalitiesPreferencesData[],
    crmID: number
  ) => {
    const preferences = finalities.map((finality) => {
      const isLegitInterest = finality.legalBases.includes("LEGITIMO_INTERESSE");

      if (
        finality.contactPreference === undefined ||
        finality.contactPreference.consent === undefined
      ) {
        return {
          crmID: crmID,
          finalityID: finality.finalityID,
          description: finality.description,
          consent: isLegitInterest ? true : null,
          preferenceSms: null,
          preferenceEmail: null,
          preferenceTelephone: null,
          preferenceWhatsapp: null,
        };
      }
      return finality.contactPreference;
    });
    return preferences;
  };

  const handleGroupByProcess = (
    finalities: FinalitiesPreferencesData[],
    crmID: number
  ) => {
    const groups: GroupByProcess[] = [];
    for (let i = 0; i < finalities.length; i++) {
      const isLegitInterest = finalities[i].legalBases.includes("LEGITIMO_INTERESSE");

      const preference = {
        crmID: crmID,
        finalityID: finalities[i].finalityID,
        description: finalities[i].description,
        consent: isLegitInterest ? true : null,
        preferenceSms: null,
        preferenceEmail: null,
        preferenceTelephone: null,
        preferenceWhatsapp: null,
      };
      for (let j = 0; j < finalities[i].process.length; j++) {
        const indexProcess = groups.findIndex(
          ({ processID }) => processID === finalities[i].process[j].processID
        );

        if (indexProcess === -1) {
          if (
            finalities[i].contactPreference === undefined ||
            finalities[i].contactPreference.consent === undefined
          ) {
            const newGroup = {
              crmID: crmID,
              processID: finalities[i].process[j].processID,
              processName: finalities[i].process[j].processName,
              finalities: [
                {
                  finalityID: finalities[i].finalityID,
                  description: finalities[i].description,
                  contactPreference: preference,
                },
              ],
            };
            groups.push(newGroup);
          } else {
            const newGroup = {
              crmID: crmID,
              processID: finalities[i].process[j].processID,
              processName: finalities[i].process[j].processName,
              finalities: [
                {
                  finalityID: finalities[i].finalityID,
                  description: finalities[i].description,
                  contactPreference: finalities[i].contactPreference,
                },
              ],
            };
            groups.push(newGroup);
          }
        } else {
          if (
            finalities[i].contactPreference === undefined ||
            finalities[i].contactPreference.consent === undefined
          ) {
            groups[indexProcess].finalities.push({
              finalityID: finalities[i].finalityID,
              description: finalities[i].description,
              contactPreference: preference,
            });
          } else {
            groups[indexProcess].finalities.push({
              finalityID: finalities[i].finalityID,
              description: finalities[i].description,
              contactPreference: finalities[i].contactPreference,
            });
          }
        }
      }
    }
    return groups;
  };

  function verificaFinaidades( finality ) {
    if ( account.manageLegitInterest ) {
      return (
        (finality.legalBases.includes("CONSENTIMENTO") || finality.legalBases.includes("LEGITIMO_INTERESSE")) && finality.showPortal
      );
    }
    return (
      finality.legalBases.includes("CONSENTIMENTO") && finality.showPortal
    );
  }

  async function sendConsent(crmID: number, preferences: ContactPreferencesData) {
    const dataSend: ContactPreferencesDataSend = {
      ...preferences,
      justification: "Alterado pelo cliente via Portal do Titular."
    };
    const send = () => api.post(`v1/auth/finality/${crmID}`, dataSend);
    await handleRequest(send);
  };

  return { getFinalities, sendConsent, getPreferences, getGroupedFinalities };
};
