import { Avatar, Box, BoxProps, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";
import clsx from "clsx";

import { DomainContext, useApi } from "@syonet/gpd-hooks";
import useStyles from "./MenuSectionLogo.style";

import defaultLogo from "../../assets/logo2.svg";

/* eslint-disable-next-line */
export interface MenuSectionLogoProps {
  boxProps?: BoxProps;
  imgSize?: string;
}

export function MenuSectionLogo({ imgSize, boxProps }: MenuSectionLogoProps) {
  const classes = useStyles();
  const { account } = useContext(DomainContext);
  const { resolveLogoPath } = useApi();
  const [logo, setLogo] = useState(null);
  const { className = "", color = "", ...rest } = boxProps ? boxProps : {};

  React.useEffect(() => {
    if (!account) return;
    const logoURL = resolveLogoPath(account);
    setLogo(logoURL ?? defaultLogo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <Box
      className={clsx(classes.root, className ? className : undefined)}
      {...rest}
    >
      <section className={classes.menuLogoSection}>
        <Avatar className={clsx(classes.logo, imgSize)} src={logo} alt="logo" />
        <Typography className={clsx(color, classes.menuNameCompany)}>
          {!!account && !!account.name ? account.name : "Syonet CRM"}
        </Typography>
      </section>
    </Box>
  );
}

export default MenuSectionLogo;
