import React from "react";

import MaskedInput from 'react-text-mask';

import "./GenericTextMask.css";

export interface GenericTextMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  showMask?: boolean;
  placeholderChar?: string;
  mask: (string | RegExp)[]
}

export function GenericTextMask(props: GenericTextMaskProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
}

export default GenericTextMask;
