import { Box } from "@material-ui/core";
import "../menu-column-form/MenuColumnForm.css";
import React from "react";
import MenuColumnForm from "../menu-column-form/MenuColumnForm";
import dadosCompletos from "../../../assets/dadosCompletos.svg";
import { useStyles } from "../menu-column-form/MenuColumnForm";

export interface CompleteDataDTO {
  label: string;
}

export interface CompleteDataProps {
  onSubmit: (param: CompleteDataDTO) => void;
}

export function CompleteData({ onSubmit }: CompleteDataProps) {
  const classes = useStyles();
  const submitHandler = (data: CompleteDataDTO) => {
    onSubmit(data);
  };

  return (
    <MenuColumnForm button={{ onSubmit: submitHandler }}>
      <img id="menu-column-icon" src={dadosCompletos} alt="computer" />
      <Box id="menu-column-title" className={classes.secondaryColor}>
        Solicitação de dados completos
      </Box>
    </MenuColumnForm>
  );
}

export default CompleteData;
