import { Box, Container, Grid, useTheme } from "@material-ui/core";
import {
  ConfirmDialog,
  WarningDialog,
  ConfirmAuthForm,
  ConfirmCodeForm,
  Copyright,
  Banner,
  HeaderBar,
  PageContent,
} from "@portal-do-titular/ui-components";
import React, { useContext } from "react";

import authPage from "../../../assets/authPage.svg";
import {
  usePerson,
  useAuthCode,
  PersonCRM,
  useHiddenMask,
  AuthContext,
} from "@syonet/gpd-hooks";
import { useLocation } from "react-router-dom";
import { useConfDataStyle } from "./ConfirmationOfData.style";

const ConfirmationOfData = () => {
  const theme = useTheme();
  const classes = useConfDataStyle();
  const { state: person } = useLocation<PersonCRM>();
  const { logout } = useContext(AuthContext);
  const { hiddenEmail, hiddenTelephone } = useHiddenMask();
  const [openSendCodeDialog, setOpenSendCodeDialog] = React.useState(false);
  const [openCodeNotSent, setOpenCodeNotSent] = React.useState(false);
  const [openCodeCorrectDialog, setOpenCodeCorrectDialog] = React.useState(
    false
  );
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openInvalidCodeDialog, setOpenInvalidCodeDialog] = React.useState(
    false
  );

  const { sendConfirmCode } = useAuthCode();
  const { activatePerson } = usePerson();
  const handleDrawerOpen = () => {
    setOpenMenu(true);
  };

  const handleDrawerClose = () => {
    setOpenMenu(false);
  };

  const sendCode = async (param) => {
    try {
      await sendConfirmCode(param);
      setOpenSendCodeDialog(true);
    } catch (e) {
      setOpenCodeNotSent(true);
    }
  };

  const confirmCode = async (param) => {
    const {
      data: { status },
    } = await activatePerson(param);

    if (status === 0) {
      setOpenCodeCorrectDialog(true);
      return;
    }
    setOpenInvalidCodeDialog(true);
  };

  const handleInvalidCodeDialogClose = (value) => {
    setOpenInvalidCodeDialog(value);
  };

  return (
    <Box className={classes.root}>
      <HeaderBar
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
        isOpen={openMenu}
      />
      <PageContent>
        <ConfirmDialog
          title="Olá,"
          message="Código validado, você está pronto para acessar o menu de opções no Portal do Titular. Para sua segurança será necessário que realize o login com seu usuário e senha para confirmação."
          hasButton
          labelButton="REALIZAR LOGIN"
          handleClickButton={() => logout()}
          state={[openCodeCorrectDialog, setOpenCodeCorrectDialog]}
        />
        <ConfirmDialog
          title="Olá,"
          message="Foi enviado um código de 4 dígitos, assim que receber preencha o campo abaixo."
          hasButton
          labelButton="OK"
          handleClickButton={() => console.log("OK")}
          state={[openSendCodeDialog, setOpenSendCodeDialog]}
        />
        <WarningDialog
          title="Olá,"
          message="Seu código esta inválido, tente de novo por favor!"
          state={[openInvalidCodeDialog, handleInvalidCodeDialogClose]}
        />
        <WarningDialog
          title="Olá,"
          message="Não foi possível lhe enviar o código. Por favor, se possível tente outro meio ou tente mais tarde!"
          state={[openCodeNotSent, setOpenCodeNotSent]}
        />
        <Box mx={2} >
          <Banner
            title="PORTAL DO TITULAR"
            description="Seus dados foram encontrados com sucesso em nossa base de dados. Para sua segurança precisamos confirmar se você, é você mesmo. Agora escolha uma das opções de contato para receber um código de segurança, após receber esse código, insira no local indicado. Pronto, é necessário se logar novamente para navegar no Portal. "
            img={authPage}
          />
        </Box>
        <Box my={2} >
          <Grid container justify="space-evenly" direction="row">
            <Grid item md={5} xs={11} className={classes.cards}>
              <ConfirmAuthForm
                onSubmit={sendCode}
                button={{ label: "Continuar" }}
                email={hiddenEmail(person?.crmEmail)}
                telefone={hiddenTelephone(
                  person?.dddTelephone,
                  person?.telephone
                )}
                personId={person?.id}
                description="Olá, escolha SMS ou E-mail para rececer nossa confirmação  de acesso ao cadastro. Iremos enviar um código que servirá de confirmação para acessar seu cadastro."
              />
            </Grid>
            <Grid item md={5} xs={11}>
              <ConfirmCodeForm onSubmit={confirmCode} />
            </Grid>
          </Grid>
        </Box>
      </PageContent>
      <div className={classes.footer}>
        <Container maxWidth="lg">
          <Copyright />
        </Container>
      </div>
    </Box>
  );
};

export default ConfirmationOfData;
