import useApi from "./useApi";

export interface VerifyCodeData {
  code: string;
  personId: number;
  invalidate: boolean;
}

export interface VerifyCodeOutput {
  status: number;
  message: string;
}

export interface SendConfirmCodeParam {
  value: string;
  type: "EMAIL" | "PHONE";
  personId: number;
}

export const useAuthCode = () => {
  const { api } = useApi();

  const validate = async (
    codeData: VerifyCodeData
  ): Promise<VerifyCodeOutput> => {
    const { data } = await api.post("v1/auth/code", codeData);
    return data;
  };

  const sendConfirmCode = async (codeData: SendConfirmCodeParam) => {
    const { data } = await api.post("v1/auth/code/send", codeData);
    return data;
  };

  return { validate, sendConfirmCode };
};

export default useAuthCode;
