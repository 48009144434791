import {
  Box,
  Button,
  createStyles,
  Dialog,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";
import { DomainContext } from "@syonet/gpd-hooks";
import React, { useContext } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      padding: theme.spacing(3),
    },
    content: {
      maxHeight: "300px",
      overflowY: "auto",
    },
    list: {
      listStyle: "none",
      marginTop: "10px",
    },
    textBox: {
      marginTop: "10px",
      minHeight: "21px",
    },
    buttonContainer: {
      paddingTop: "10px",
      height: "50px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    closeButton: {
      width: "110px",
    },
  })
);

function InfoCardHeader() {
  return (
    <Box>
      <Typography variant="subtitle1">
        Finalidade de Autorização
      </Typography>
    </Box>
  );
}

function InfoCardContent(props: {
  children?: React.ReactNode
}) {
  const classes = useStyles();
  return (
    <Box className={classes.content}>
      {props.children}
    </Box>
  );
}

function InfoCardActions(props: {
  onClose: () => void
}) {
  const classes = useStyles();
  return (
    <Box className={classes.buttonContainer}>
      <Button
        size="small"
        onClick={() => props.onClose()}
        variant="contained"
        color="primary"
        className={classes.closeButton}
      >
        Fechar
      </Button>
    </Box>
  );
}

function CustomMessageInfo() {
  const classes = useStyles();
  const { account } = useContext(DomainContext);
  const customMessage = account?.finalityDescription ? account.finalityDescription
    : ("É um prazer em receber você, estamos muito felizes em proteger seus dados juntamente com você."
      + " Para acessar o Portal do Titular, é necessário que você aceite/concorde"
      + " com a finalidade de uso de seus dados do cadastro no portal.")
  const paragraphs = customMessage.split("\n");
  return (
    <>
      {paragraphs.map(message => (
        <Box className={classes.textBox}>
          <Typography variant="caption">
            {message || " "}
          </Typography>
        </Box>
      ))}
    </>
  );
}

export function FinalityInfoCard(props: {
  open: boolean,
  onClose: () => void
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      className={classes.root}
    >
      <Paper className={classes.paper}>
        <InfoCardHeader />
        <InfoCardContent>
          <CustomMessageInfo />
        </InfoCardContent>
        <InfoCardActions onClose={props.onClose} />
      </Paper>
    </Dialog>
  );
}

export default FinalityInfoCard;
