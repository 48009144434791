import { Box, Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Footer, HeaderBar } from "@portal-do-titular/ui-components";
import React, { ReactNode } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      maxWidth: "100%",
    },
  })
);

function HeaderSection(props: {
  override?: ReactNode;
  hasLogout?: boolean;
  onHome?: () => void;
  onKnowMore?: () => void;
}) {
  const [statusMenu, setStatusMenu] = React.useState(false);
  const openMenu = () => setStatusMenu(true);
  const closeMenu = () => setStatusMenu(false);
  if (props.override) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{props.override}</>;
  }
  return (
    <HeaderBar
      onOpen={openMenu}
      onClose={closeMenu}
      isOpen={statusMenu}
      isLogout={props.hasLogout}
      onHome={props.onHome}
      onKnowMore={props.onKnowMore}
    />
  );
}

function FooterSection(props: {
  override?: ReactNode;
}) {
  if (props.override) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{props.override}</>;
  }
  return <Footer />;
}

function OneColumnLayout(props: {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  hasLogout?: boolean;
  onHome?: () => void;
  onKnowMore?: () => void;
}) {
  const classes = useStyles();
  return (
    <Container className={classes.root} disableGutters >
      <HeaderSection
        override={props.header}
        hasLogout={props.hasLogout}
        onHome={props.onHome}
        onKnowMore={props.onKnowMore}
      />
      <Box>
        {props.children}
      </Box>
      <FooterSection
        override={props.footer}
      />
    </Container>
  );
}

export default OneColumnLayout;
