import { createStyles, makeStyles } from "@material-ui/core";

export default makeStyles((theme) =>
  createStyles({
    root: {
      width: "370px",
      [theme.breakpoints.down("xs")]: {
        width: "295px",
      },
    },
    menuLogoSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "24px",
      },
    },
    logo: {
      marginRight: "32px",
      width: "50px",
      height: "50px",
      [theme.breakpoints.down("md")]: {
        marginRight: "10px",
        width: "40px",
        height: "40px",
      },
    },
    menuNameCompany: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "36px",
      letterSpacing: "-0.015em",
      [theme.breakpoints.down("md")]: {
        fontFamily: "Montserrat",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "36px",
        letterSpacing: "-0.015em",
      },
    },
    //o JordaniCaldeir Muique iMuito
  })
);
