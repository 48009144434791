export const useHiddenMask = () => {
  const hiddenEmail = (data: string) => {
    if (!data || !data.includes("@")) {
      return "";
    }
    const atSlipt = data.split("@");
    const userEmail =
      atSlipt[0].charAt(0) + atSlipt[0].replaceAll(/[[aA-zZ]|\d]/g, "*") + "@";
    const dotComSplit = atSlipt[1].split(".");
    const domainEmail =
      dotComSplit[0].charAt(0) +
      dotComSplit[0].charAt(1) +
      dotComSplit[0].replaceAll(/[[aA-zZ]|\d]/g, "*") +
      "." +
      dotComSplit[1];

    return userEmail + domainEmail;
  };

  const hiddenTelephone = (ddd: string, telephone: string) => {
    let dddMask = "";
    let telMask = "";
    if (ddd && ddd.length >= 2) {
      dddMask = ddd.charAt(0) + ddd.charAt(1).replaceAll(/[0-9]/g, "*");
    }
    if (telephone && telephone.length >= 8) {
      telMask =
        telephone.substring(5, 0) +
        telephone.substring(5).replaceAll(/[0-9]/g, "*");
    }
    return dddMask + telMask;
  };

  return { hiddenEmail, hiddenTelephone };
};

export default useHiddenMask;
