import { Box } from "@material-ui/core";
import React from "react";
import "../menu-column-form/MenuColumnForm.css";
import MenuColumnForm from "../menu-column-form/MenuColumnForm";
import gerenciarConsentimento from "../../../assets/gerenciarConsentimento.svg";
import { useStyles } from "../menu-column-form/MenuColumnForm";

export interface ConsentManagerDTO {
  label: string;
}

export interface ConsentManagerProps {
  onSubmit: (param: ConsentManagerDTO) => void;
}

export function ConsentManager({ onSubmit }: ConsentManagerProps) {
  const classes = useStyles();
  const submitHandler = (data: ConsentManagerDTO) => {
    onSubmit(data);
  };

  return (
    <MenuColumnForm button={{ onSubmit: submitHandler }}>
      <img
        id="menu-column-icon"
        src={gerenciarConsentimento}
        alt="notebook and pen"
      />
      <Box id="menu-column-title" className={classes.secondaryColor}>
        Gerenciar Consentimentos
      </Box>
    </MenuColumnForm>
  );
}

export default ConsentManager;
