import { Container, Box } from "@material-ui/core";
import React from "react";
import "../menu-column-form/MenuColumnForm.css";
import MenuColumnForm from "../menu-column-form/MenuColumnForm";
import dataPurposes from "../../../assets/dataPurposes.svg";

export interface DataPurposesDTO {
  label: string;
}

export interface DataPurposesProps {
  onSubmit: (param: DataPurposesDTO) => void;
}

export function DataPurposes({ onSubmit }: DataPurposesProps) {
  const submitHandler = (data: DataPurposesDTO) => {
    onSubmit(data);
  };

  const classCenter = "Menu-column-class-center";

  return (
    <MenuColumnForm button={{ onSubmit: submitHandler }}>
      <Container>
        <Box margin="5px">
          <div id="Menu-column-icon" className={classCenter}>
            <img src={dataPurposes} />
          </div>
          <div id="Menu-column-title" className={classCenter}>
            Finalidades com tratamentos e dados
          </div>
        </Box>
      </Container>
    </MenuColumnForm>
  );
}

export default DataPurposes;
