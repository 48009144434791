import { createStyles, makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    formSection: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      paddingBottom: "30px",
      margin: "auto",
      "@media (max-width: 600px)": {
        flexDirection: "column",
      },
    },
    footer: {
      backgroundColor: theme.palette.secondary.main,
      width: "100%",
    },
  })
);
