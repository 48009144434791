import { Breadcrumbs, Chip, LinearProgress } from "@material-ui/core";
import { emphasize, Theme, withStyles } from "@material-ui/core/styles";
import { DomainContext, LoadingContext } from "@syonet/gpd-hooks";
import React, { useContext } from "react";
import { Route, Link } from "react-router-dom";

import "./Breadcrumbs.css";

export const BreadcrumbItem = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip) as typeof Chip;

export interface BreadcrumbsProps {
  children: React.ReactNode;
}

export function HistoryBreadcrumbs({ children }: BreadcrumbsProps) {
  return (
    <Breadcrumbs maxItems={4} aria-label="breadcrumb">
      {children}
    </Breadcrumbs>
  );
}

export function AutoBreadcrumbs() {
  const { isLoading } = useContext(LoadingContext);
  const { slug } = useContext(DomainContext);
  return (
    <Route>
      {({ location }) => {
        const pathnames: string[] = location.pathname
          .split("/")
          .filter((x) => x);
        return (
          <>
            <HistoryBreadcrumbs>
              <BreadcrumbItem component="a" href={`${slug}/`} label="home" />
              {pathnames.map((value, index) => {
                if (value === slug) {
                  return null;
                }
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;
                const label = value.replaceAll("-", " ");
                return last ? (
                  <BreadcrumbItem key={to} component="a" label={label} />
                ) : (
                  <BreadcrumbItem
                    key={to}
                    component="a"
                    href={to}
                    label={label}
                  />
                );
              })}
            </HistoryBreadcrumbs>
            {isLoading && <LinearProgress />}
          </>
        );
      }}
    </Route>
  );
}

export default HistoryBreadcrumbs;
