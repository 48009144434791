import { createStyles, Grid, Hidden, makeStyles } from "@material-ui/core";
import { Copyright } from "@portal-do-titular/ui-components";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    copyright: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      backgroundColor: theme.palette.secondary.main,
      alignItems: "center",
      alignContent: "space-evenly",
      zIndex: 3
    },
  })
);

export function BasicFooter() {
  const classes = useStyles();
  return (
    <Hidden mdUp >
      <Grid container className={classes.copyright} >
        <Copyright />
      </Grid>
    </Hidden>
  );
}

export default BasicFooter;
