import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardInfo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundColor: theme.palette.primary.light,
      width: "100%",
      padding: "24px",
      position: "fixed",
      left: "0px",
      bottom: "0px",
      maxWidth: "none",
      boxShadow: theme.shadows[2],
      gap: "16px",
      zIndex: 9999,
      "@media screen and (max-width: 1230px)": {
        flexDirection: "column",
      },
    },
  })
);

export { useStyles };
