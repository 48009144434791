import {
  Card,
  Button,
  makeStyles,
  Theme,
  createStyles,
  Typography,
} from "@material-ui/core";
import { Form } from "@unform/web";
import React from "react";

import "./SimpleStepsForm.css";

/* eslint-disable-next-line */
export interface SimpleStepsFormProps {
  step: number;
  description: string;
  children: React.ReactNode;
  button?: {
    onSubmit: (data: unknown) => void;
    label: string;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 340,
      padding: theme.spacing(1.5),
    },
    colorPrimary: {
      color: "#1e1e1e",
    },
  })
);

export function SimpleStepsForm(props: SimpleStepsFormProps) {
  const { step, description, children, button } = props;
  const classes = useStyles();
  const classCenter = "simple-step-class-center";

  return (
    <Card className={classes.root}>
      <div
        id="simple-step-title"
        className={classes.colorPrimary}
      >{`Passo ${step}`}</div>
      <Typography variant="caption" className={classes.colorPrimary}>
        {description}
      </Typography>
      {button ? (
        <Form onSubmit={button.onSubmit}>
          <div className={classCenter}>
            {children}
            {button && (
              <Button
                type="submit"
                id="simple-step-button"
                variant="contained"
                color="primary"
              >
                <Typography variant="subtitle2" color="textPrimary">
                  {button.label}
                </Typography>
              </Button>
            )}
          </div>
        </Form>
      ) : (
        children
      )}
    </Card>
  );
}

export default SimpleStepsForm;
