import React from "react";
import { Box, Typography } from "@material-ui/core";
import CopyrightIcon from "@material-ui/icons/Copyright";
import { useCopyrightStyles } from "./Copyright.style";

import syonetLogo from "../../../assets/logo2.svg";

/* eslint-disable-next-line */
export interface CopyrightProps {
  background?: boolean;
  center?: boolean;
}

export function Copyright(props: CopyrightProps) {
  const classes = useCopyrightStyles();
  const year = new Date().getFullYear();
  const background = props.background;
  const center = props.center;

  return (
    <Box className={classes.container}>
      <img className={classes.logo} src={syonetLogo} alt="syonet logo" />
      <Box className={classes.containerText}>
        <Typography className={`${classes.textColor} ${classes.text}`}>
          Copyright
        </Typography>
        <CopyrightIcon fontSize="small" className={classes.textColor} />
        <Typography className={`${classes.textColor} ${classes.text}`}>
          {`Syonet ${year}.`}
        </Typography>
      </Box>
    </Box>
  );
}

export default Copyright;
