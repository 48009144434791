import { AuthContext, DomainContext } from "@syonet/gpd-hooks";
import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import ConfirmationOfData from "./pages/confirmation-of-data/ConfirmationOfData";
import CookiesPolicy from "./pages/cookies-policy/cookies-policy";
import DualAuthentication from "./pages/dual-authentication/DualAuthentication";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Menus from "./pages/menus/Menus";
import PageNotFound from "./pages/page-not-found/PageNotFound";
import Register from "./pages/register/Register";
import Showroom from "./showroom/Showroom";

const slug = "/:slug";

const routes = [
  {
    isOpen: true,
    path: `${slug}/login`,
    exact: false,
    component: Login,
  },
  {
    isOpen: false,
    path: `${slug}/autenticacao-dupla`,
    exact: true,
    component: DualAuthentication,
  },
  {
    isOpen: true,
    path: `${slug}/cadastro`,
    exact: true,
    component: Register,
  },
  {
    isOpen: false,
    path: `${slug}/autenticacao-dupla/confirmar-dados`,
    exact: true,
    component: ConfirmationOfData,
  },
  {
    isOpen: true,
    path: "/showcase",
    exact: false,
    component: Showroom,
  },
  {
    isOpen: true,
    path: `${slug}/`,
    exact: true,
    component: Home,
  },
  {
    isOpen: true,
    path: `${slug}#saiba-mais`,
    exact: true,
    component: Home,
  },
  {
    isOpen: false,
    path: `${slug}/perfil`,
    exact: true,
    component: Menus,
  },
  {
    isOpen: true,
    path: `/PageNotFound`,
    exact: false,
    component: PageNotFound,
  },
  {
    isOpen: true,
    path: `${slug}/politica-de-uso-de-cookies`,
    exact: true,
    component: CookiesPolicy,
  },

  // {
  //   isOpen: true,
  //   path: `${slug}/perfil/gerenciador-consentimentos`,
  //   exact: true,
  //   component: ConsentManagerForm,
  // },
];

const OpenRoutes = () => (
  <React.Fragment>
    {routes
      .filter(({ isOpen }) => isOpen)
      .map(({ component, path, exact }, index) => (
        <Route key={index} {...{ component, path, exact }} />
      ))}
  </React.Fragment>
);

const AuthRoutes = () => (
  <React.Fragment>
    {routes.map(({ component, path, exact }, index) => (
      <Route key={index} {...{ component, path, exact }} />
    ))}
  </React.Fragment>
);

const Routes = () => {
  const { signed } = useContext(AuthContext);
  const { account } = useContext(DomainContext);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>
      {account && account.status && (signed ? <AuthRoutes /> : <OpenRoutes />)}
      <Route component={PageNotFound} path="/PageNotFound" />
    </React.Fragment>
  );
};

export default Routes;
