import { Avatar, Box, createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textHeader: {
      color: theme.palette.secondary.contrastText,
      fontSize: "18px",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "36px",
    },
    header: {
      display: "flex",
      alignItems: "center",
    },
    logo: {
      marginRight: "32px",
      width: "60px",
      height: "60px",
      [theme.breakpoints.down("md")]: {
        marginRight: "10px",
        width: "40px",
        height: "40px",
      },
    }
  })
);

export function SystemHeader(props: {
  logo?: string;
  name?: string;
}) {
  const classes = useStyles();
  const text = props.name || "Syonet CRM";
  return (
    <Box className={classes.header} >
      <Avatar className={classes.logo} src={props.logo} alt="logo" />
      <Typography variant="h1" className={classes.textHeader} >
        {text}
      </Typography>
    </Box>
  );
}

export default SystemHeader;
