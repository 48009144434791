import React from "react";
import "../menu-column-form/MenuColumnForm.css";
import MenuColumnForm from "../menu-column-form/MenuColumnForm";
import myRequests from "../../../assets/myRequests.svg";
import { useStyles } from "../menu-column-form/MenuColumnForm";

export interface MyRequestsDTO {
  label: string;
}

export interface MyRequestsProps {
  onSubmit: (param: MyRequestsDTO) => void;
}

export function MyRequests({ onSubmit }: MyRequestsProps) {
  const classes = useStyles();
  const submitHandler = (data: MyRequestsDTO) => {
    onSubmit(data);
  };

  return (
    <MenuColumnForm button={{ onSubmit: submitHandler }}>
      <img id="menu-column-icon" src={myRequests} alt="balloon talk" />
      <div id="menu-column-title" className={classes.secondaryColor}>
        Minhas Solicitações
      </div>
    </MenuColumnForm>
  );
}

export default MyRequests;
