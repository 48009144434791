import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      width: "100%",
      height: "100%",
      borderRadius: "0px 6% 6% 0px",
      padding: "5% 5% 18% 100px",
      display: "Grid",
      gridTemplateRows: "auto 90% auto",
      zIndex: 1,
      backgroundColor: theme.palette.secondary.main,
    },
    text: {
      color: theme.palette.secondary.contrastText,
    },
    textHeader: {
      color: theme.palette.secondary.contrastText,
      fontSize: "18px",
      fontFamily: "Montserrat",
      fontWeight: 700,
      lineHeight: "36px",
    },
    header: {
      display: "flex",
      alignItems: "center",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    logo: {
      marginRight: "32px",
      width: "60px",
      height: "60px",
      [theme.breakpoints.down("md")]: {
        marginRight: "10px",
        width: "40px",
        height: "40px",
      },
    },
    personalMessageBlockHeader: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minWidth: "100%",
      marginBottom: "30px",
    },
    personalMessageBlockContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "500px",
    },
    body: {
      display: "flex",
      flexDirection: "column",
    },
    personIcon: {
      color: theme.palette.text.secondary,
      fontSize: "128px",
    },
    personalDetails: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
    detailIcon: {
      marginRight: "17px",
      color: theme.palette.secondary.contrastText,
    },
  })
);

export { useStyles };
