import { Box, Button, Container, Fade, Grid, LinearProgress, Typography } from "@material-ui/core";
import { BannerInfo, ConfirmDialog, ConsentManagerForm, ContactPreferenceForm, MenuCard, PersonInfoCard, QuestionDialog, RequestDescription, WarningDialog } from "@portal-do-titular/ui-components";
import { AuthContext, DomainContext, MenusContext, useApi } from "@syonet/gpd-hooks";
import React, { useContext, useState } from "react";
import ConnectWithContactIcon from "../../../assets/connect_without_contact_black_24dp.svg";
import EditNoteIcon from "../../../assets/edit_note_black_24dp.svg";
import InventoryIcon from "../../../assets/inventory_black_24dp.svg";
import PrivacityTipIcon from "../../../assets/privacy_tip_black_24dp.svg";
import PublishIcon from "../../../assets/publish_black_24dp.svg";
import RemoveCircleOutlineIcon from "../../../assets/remove_circle_outline_black_24dp.svg";
import RuleIcon from "../../../assets/rule_black_24dp.svg";
import ShareIcon from "../../../assets/share_black_24dp.svg";
import SmartToyIcon from "../../../assets/smart_toy_black_24dp.svg";
import TextSnippetIcon from "../../../assets/text_snippet_black_24dp.svg";
import ColumnsLayout from "../layout/ColumnsLayout";
import { useStyles } from "./Menus.style";

function DataManager() {
  const { account } = useContext(DomainContext);
  const { person } = useContext(AuthContext);
  const { consentManager } = useContext(MenusContext);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openQuestionDialog, setOpenQuestionDialog] = useState(false);
  const [openConsentSaveDialog, setOpenConsentSaveDialog] = useState(false);
  const [validateField, setValidateField] = useState("");
  const [showMenus, setShowMenus] = useState(true);
  const [showRequisition, setShowRequisition] = useState(false);
  const [showConsentManager, setShowConsentManager] = useState(false);
  const [showContactManager, setShowContactManager] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const urlPrivacyPolicy = useApi().resolvePrivacyPolicyPath(account);

  function handleDialogClose(value: boolean) {
    setOpenSuccessDialog(value);
  }
  function handleConsentDialogClose(value: boolean) {
    setOpenConsentSaveDialog(value);
  }
  function onOpenDialog(event?: string) {
    if (event === "requestData") {
      setValidateField("Solicitação de dados completos");
    } else {
      setValidateField("Correção de dados");
    }
  }
  function handleMenus() {
    setShowMenus(false);
    setShowRequisition(true);
  }
  function handleDescription() {
    setShowMenus(true);
    setShowRequisition(false);
  }
  function openDialog() {
    setOpenSuccessDialog(true);
  }
  async function handleConsentMagager() {
    setLoading(true);
    try {
      await consentManager(person.crmID);
      setShowMenus(false);
      setShowRequisition(false);
      setShowConsentManager(true);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setOpenQuestionDialog(true);
    }
  }
  function handleConsentCancel() {
    setShowMenus(true);
    setShowRequisition(false);
    setShowConsentManager(false);
  }
  function handleConsentSave() {
    setShowMenus(true);
    handleConsentDialogClose(true);
    setShowRequisition(false);
    setShowConsentManager(false);
  }
  function handleContact() {
    setShowMenus(false);
    setShowContactManager(true);
  }
  function handleContactClose() {
    setShowContactManager(false);
    setShowMenus(true);
  }

  const menus = [
    {
      enum: "SOLICITAR_DADOS",
      name: "Solicitação de dados completos",
      img: TextSnippetIcon,
      action: () => {
        handleMenus();
        onOpenDialog("requestData");
      },
    },
    {
      enum: "GERENCIAR_CONSENTIMENTO",
      name: "Gerenciar Consentimentos",
      img: RuleIcon,
      action: () => {
        handleConsentMagager();
      },
    },
    {
      enum: "MINHAS_SOLICITACOES",
      name: "Minhas Solicitações",
      img: InventoryIcon,
      action: openDialog,
    },
    {
      enum: "CONSULTA_COMPARTILHAMENTO_DADOS",
      name: "Consulta de compartilhamento de dados",
      img: ShareIcon,
      action: openDialog,
    },
    {
      enum: "CORRECAO_DADOS",
      name: "Correção de dados",
      img: EditNoteIcon,
      action: () => {
        handleMenus();
        onOpenDialog();
      },
    },
    {
      enum: "PREFERENCIA_DE_CONTATO",
      name: "Preferência de contato",
      img: ConnectWithContactIcon,
      action: () => {
        handleContact();
      },
    },
    {
      enum: "PORTABILIDADE_DE_DADOS",
      name: "Portabilidade de dados",
      img: PublishIcon,
      action: () => {
        handleMenus();
        setValidateField("Portabilidade de dados");
      },
    },
    {
      enum: "ANOMALIZACAO_BLOQUEIO_EXCLUSAO_DADOS",
      name: "Anonimização, bloqueio ou exclusão de dados",
      img: RemoveCircleOutlineIcon,
      action: () => {
        handleMenus();
        setValidateField("Anonimização, bloqueio ou exclusão de dados");
      },
    },
    {
      enum: "CONTESTACAO_DADOS",
      name: "Contestar decisão automatizada",
      img: SmartToyIcon,
      action: () => {
        handleMenus();
        setValidateField("Contestar decisão automatizada");
      },
    },
    {
      enum: "POLITICA_DE_PRIVACIDADE",
      name: "Politica de Privacidade",
      img: PrivacityTipIcon,
      action: (e) => {
        const actionHref = () => {
          e.preventDefault();
          window.location.href = urlPrivacyPolicy;
        };
        account.pathPrivacyPolicy ? actionHref() : openDialog();
      },
    },
  ];

  return (
    <>
      <Fade mountOnEnter unmountOnExit in={showMenus}>
        <Grid
          container
          justify="center"
          direction="column"
          alignItems="center"
        >
          <Box className={classes.titleConteiner}  >
            <Typography variant="h5">Menu</Typography>
          </Box>
          <BannerInfo text="Escolha o menu de acordo com a sua necessidade." />
          <Container>
            {menus
              .filter((e) => account.menus.includes(e.enum))
              .map(({ action, img, name }) => {
                return (
                  <Button
                    key={name}
                    onClick={action}
                    className={classes.card}
                  >
                    <MenuCard
                      img={img}
                      name={name}
                      onClick={(name) => console.log(name)}
                    />
                  </Button>
                );
              })}
          </Container>
        </Grid>
      </Fade>
      <Fade mountOnEnter unmountOnExit in={showRequisition}>
        <Container>
          <Grid
            container
            justify="space-evenly"
            direction="column"
            alignItems="center"
            className={classes.titleConteiner}
          >
            <Box mb={2}>
              <Typography variant="h5">Nova Solicitação</Typography>
            </Box>
            <Grid item>
              <RequestDescription
                onSubmit={(param) => console.log(param)}
                setSubject={validateField}
                viewMenus={handleDescription}
              />
            </Grid>
          </Grid>
        </Container>
      </Fade>
      <Fade mountOnEnter unmountOnExit in={showConsentManager}>
        <ConsentManagerForm
          onCancel={handleConsentCancel}
          onSave={handleConsentSave}
        />
      </Fade>
      <Fade mountOnEnter unmountOnExit in={showContactManager}>
        <ContactPreferenceForm onClose={handleContactClose} />
      </Fade>
      <WarningDialog
        title="Atenção,"
        message={
          account?.contactDialogText
            ? account.contactDialogText
            : "Entre em contato com sua concessionária. Obrigado!"
        }
        state={[openSuccessDialog, handleDialogClose]}
      />
      <ConfirmDialog
        title="Eba!!!"
        message="Seus consentimentos foram salvos com sucesso."
        hasButton
        labelButton="Ok"
        handleClickButton={() => console.log("Ok")}
        state={[openConsentSaveDialog, handleConsentDialogClose]}
      />
      <QuestionDialog
        title="Olá,"
        message="Informamos que seus dados não foram localizados em nosso banco de dados ativo OU existe alguma inconsistência no seu preenchimento.\nVocê gostaria de tentar realizar uma nova busca ou abrir uma solicitação diretamente junto ao nosso DPO?\nFavor escolher a opção desejada abaixo:"
        labelButton1="Tentar Novamente"
        labelButton2="Abrir solicitação"
        handleClickButton1={() => console.log("Tentar novamente!")}
        handleClickButton2={() => console.log("Abrir solicitação")}
        state={[openQuestionDialog, setOpenQuestionDialog]}
      />
      {loading && <LinearProgress />}
    </>
  );
}

function PersonDetail() {
  const { person } = useContext(AuthContext);
  return (
    <PersonInfoCard
      ddd={person.dddTelephone}
      name={person?.name}
      email={person?.email}
      telephone={person.telephone}
    />
  );
}

function Menus() {
  return (
    <ColumnsLayout
      data={PersonDetail}
      hasLogout
    >
      <DataManager />
    </ColumnsLayout>
  );
}

export default Menus;
