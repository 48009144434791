import React from "react";

export function scrollToRef (ref: React.MutableRefObject<any>, screenRef?: number) {
  if (!ref || !ref.current) {
    return;
  }
  const screen = screenRef ? screenRef : 80; // altura do menu padrão 80px
  window.scrollTo(0, ref.current.offsetTop - screen);
}

export function scrollToRefByWrapperId (ref: React.MutableRefObject<any>, wrapper: string) {
  if (!ref || !ref.current || !wrapper) {
    return;
  }
  const element = document.getElementById(wrapper);
  if (!element){
    return;
  }
  element.scrollTo(0, ref.current.offsetTop - element.offsetTop);
}

export default {
  scrollToRefByWrapperId,
  scrollToRef
}
