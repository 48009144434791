import { boolean } from "yup";
import { useApi } from "./useApi";

export interface Person {
  id?: number;
  crmEmail?: string;
  accountID?: number;
  crmID?: number;
  isActive?: boolean;
  name: string;
  email: string;
  password: string;
  cpf?: string;
  telephone?: string;
  dddTelephone?: string;
}

export interface PersonCRM {
  id: number;
  crmEmail: string;
  crmID: number;
  email: string;
  telephone: string;
  dddTelephone: string;
  cpf: string;
  isActive: boolean;
}

export interface ActivateResponse {
  status: number;
  message: string;
}

export const usePerson = () => {
  const { api, handleRequest } = useApi();

  const create = async (person: Person) => {
    const { data, status } = await handleRequest(() =>
      api.post("v1/person", person)
    );
    return { data, status } as { data: Person; status: number };
  };

  const activatePerson = async (code) => {
    const { data, status } = await handleRequest(() =>
      api.post(`v1/person/activate/${code}`)
    );
    return { data, status } as { data: ActivateResponse; status: number };
  };

  return { create, activatePerson };
};

export default usePerson;
