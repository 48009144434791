import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";

export interface MenuCardProps {
  img: string;
  name: string;
  onClick: (name: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "175px",
      height: "175px",
      [theme.breakpoints.down("xs")]: {
        width: "125px",
        height: "125px",
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      padding: "20px",
    },
    colorPrimary: { color: "#1e1e1e" },
  })
);

export const MenuCard = ({ img, name }: MenuCardProps) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Box>
        <img alt={name} src={img} title={name} height={64} />
      </Box>
      <Box>
        <Typography variant="body2" className={classes.colorPrimary}>
          {name}
        </Typography>
      </Box>
    </Card>
  );
};
