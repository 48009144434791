import axios from "axios";
import { DomainContext, Account } from "./DomainContext";
import { AuthContext } from "./AuthContext";
import { useContext } from "react";

export const useApi = () => {
  const { domain } = useContext(DomainContext);
  const { token } = useContext(AuthContext);

  const headers = {};
  headers["Content-Type"] = "application/json";
  headers["domain"] = domain;
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const baseURL = "https://lgpd-api.dallas.linode.syonet.com/api/";
  // const baseURL = "http://localhost:8888/api/";

  const api = axios.create({
    baseURL,
    headers,
  });

  const handleRequest = async (
    func: () => Promise<{ status: number; data: unknown }>
  ) => {
    try {
      const { status, data } = await func();
      return { data, status };
    } catch (err) {
      const response = err.response;
      return {
        status: response?.status,
        message: err.message,
        data: response?.data,
      };
    }
  };

  const resolveLogoPath = ({ pathLogo, domain }: Account) => {
    if (!pathLogo) {
      return undefined;
    }
    let aux = pathLogo;
    if (pathLogo.startsWith("/")) {
      aux = pathLogo.replace("/", "");
    }
    return `${baseURL}v1/bucket/${domain}/download/${aux}`;
  };

  const resolvePrivacyPolicyPath = ({ pathPrivacyPolicy, domain }: Account) => {
    if (!pathPrivacyPolicy) {
      return undefined;
    }
    let aux = pathPrivacyPolicy;
    if (pathPrivacyPolicy.startsWith("/")) {
      aux = pathPrivacyPolicy.replace("/", "");
    }
    return `${baseURL}v1/bucket/${domain}/download/${aux}`;
  };

  return {
    api,
    handleRequest,
    resolveLogoPath,
    resolvePrivacyPolicyPath,
  };
};

export default useApi;
