import React from "react";
import MaskedInput from "react-text-mask";
import GenericTextMask, {
  GenericTextMaskProps,
} from "../generic-text-mask/GenericTextMask";

import "./TextPhoneMask.css";

export function TextPhoneMask(props: GenericTextMaskProps) {
  return (
    <GenericTextMask
      {...props}
      mask={[
        "(",
        /[1-9]/,
        /[1-9]|\*/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d|\*/,
        /\d|\*/,
        /\d|\*/,
        /\d|\*/,
      ]}
      placeholderChar={"\u2000"}
    />
  );
}

export default TextPhoneMask;
