import { DomainContext, useApi } from "@syonet/gpd-hooks";
import React, { useContext } from "react";
import defaultLogo from "../../assets/logo2.svg";
import SystemHeader from "./system-header";

export function SystemHeaderSmart() {
  const { account } = useContext(DomainContext);
  const { resolveLogoPath } = useApi();
  const [logo, setLogo] = React.useState(null);
  function atualizaLogo() {
    if (!account) {
      return;
    }
    const logoURL = resolveLogoPath(account);
    setLogo(logoURL ?? defaultLogo);
  }
  React.useEffect(atualizaLogo, [account]);
  const text = !!account && !!account.name ? account.name : "Syonet CRM";
  return (
    <SystemHeader
      logo={logo}
      name={text}
    />
  );
}

export default SystemHeaderSmart;
