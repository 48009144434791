import React, { useRef, useEffect, useContext } from "react";
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from "react-select";
import { useField } from "@unform/core";
import {
  Grid,
  GridDirection,
  GridItemsAlignment,
  GridJustification,
  SvgIconTypeMap,
  Typography,
  useTheme,
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Variant } from "@material-ui/core/styles/createTypography";

export interface Option {
  value: string;
  label: string;
  selected?: boolean;
  disabled?: boolean;
}

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon?: React.ReactElement<OverridableComponent<SvgIconTypeMap<{}, "svg">>>;
  justify?: GridJustification;
  alignItems?: GridItemsAlignment;
  direction?: GridDirection;
  labelVariant?: "inherit" | Variant;
  options: Option[];
}

export function CustomSelect({
  name,
  label,
  options,
  direction,
  alignItems,
  justify,
  labelVariant,
  icon,
  ...rest
}: Props) {
  const {
    shape: { borderRadius },
    palette: { primary },
    typography: {
      caption: { fontFamily, fontWeight, fontSize },
    },
    spacing,
  } = useTheme();
  const selectRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  React.useEffect(() => {
    registerField({
      setValue: (ref, value) => {
        console.log(ref);
        console.log(value);
      },
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Grid
      container
      justify={justify}
      alignItems={alignItems}
      direction={direction}
    >
      {icon && icon}
      <Grid item>
        <Typography variant={labelVariant}>{label}</Typography>
      </Grid>
      <Grid item>
        <ReactSelect
          placeholder={"selecione..."}
          options={options}
          styles={{
            menu: (provided, state) => ({
              ...provided,
              width: state.selectProps.width,
              border: "1px",
              borderRadius: borderRadius / 2,
              color: state.selectProps.menuColor,
              fontFamily: fontFamily,
              fontWeight: fontWeight,
              fontSize: fontSize,
              padding: spacing(1),
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              padding: spacing(1),
              fontFamily: fontFamily,
              fontWeight: fontWeight,
              fontSize: fontSize,
            }),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: borderRadius,
            spacing: { ...theme.spacing, baseUnit: 1 },
            colors: { ...theme.colors, primary: primary.main },
          })}
          ref={selectRef}
          {...rest}
        />
      </Grid>
    </Grid>
  );
}
