import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardInfo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      width: "100%",
      padding: "10px",
      marginBottom: "15px",
      [theme.breakpoints.down("md")]: {
        width: "90%",
        height: "fit-content",
        margin: "24px auto",
      },
    },
  })
);

export { useStyles };
