import { createMuiTheme, colors } from "@material-ui/core";
import shadows from "./shadows";

export interface ICompanyColors {
  primary: string;
  secondary: string;
}

export const defineColor = (color: string) => {
  const nib = color.split("");

  const r = parseInt(nib[1] + nib[2], 16);
  const g = parseInt(nib[3] + nib[4], 16);
  const b = parseInt(nib[5] + nib[6], 16);

  const luminosidade = (r * 299 + g * 587 + b * 114) / 1000;
  return luminosidade > 80;
};

const theme = (companyColors: ICompanyColors) =>
  createMuiTheme({
    overrides: {
      MuiOutlinedInput: {
        root: {
          borderRadius: 30,
          margin: 5,
        },
      },
      MuiInputBase: {
        root: {
          fontFamily: "Roboto, sans-serif",
        },
      },
      MuiButton: {
        root: {
          fontFamily: "Roboto, sans-serif",
          fontWeight: 700,
        },
      },
    },
    palette: {
      background: {
        default: colors.common.white,
        paper: colors.common.white,
      },
      primary: {
        main: companyColors.primary,
      },
      secondary: {
        main: companyColors.secondary,
      },
      text: {
        primary: "#1E1E1E",
        secondary: "#FFFFFF",
        hint: "#000000, 54%",
      },
      tonalOffset: {
        light: 0.8,
        dark: 0.3,
      },
    },
    typography: {
      fontFamily: "Montserrat, sans-serif",
      h2: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: "14px",
        fontWeight: 400,
      },
      h4: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: 48,
        fontWeight: 700,
      },
      h5: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: 28,
        fontWeight: 700,
      },
      h6: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 20,
        fontWeight: 500,
      },
      subtitle1: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: 15,
        fontWeight: 700,
        letterSpacing: "-0.015em",
      },
      subtitle2: {
        fontFamily: "Montserrat, sans-serif",
        fontSize: 15,
        fontWeight: 400,
      },
      body2: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "10px",
        fontWeight: 700,
      },
      body1: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 16,
        fontWeight: 400,
      },
      caption: {
        fontFamily: "Roboto, sans-serif",
        fontSize: 14,
        fontWeight: 400,
      },
    },
    shadows,
    shape: { borderRadius: 30 },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1070,
        lg: 1366,
        xl: 1920,
      },
    },
  });

export default theme;
