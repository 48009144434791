import React, { Dispatch, SetStateAction } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import {
  Button,
  createStyles,
  DialogActions,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { TransitionProps } from "@material-ui/core/transitions";

import img from "../../assets/ConfirmDialog1.svg";

import { useStyles } from "./ConfirmDialog.style";

/* eslint-disable-next-line */
export interface ConfirmDialogProps {
  title: string;
  message: string;
  hasButton?: boolean;
  labelButton?: string;
  handleClickButton?: () => void;
  currentWidth?: string;
  state: [boolean, Dispatch<SetStateAction<boolean>>];
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    message,
    title,
    state,
    hasButton,
    labelButton,
    handleClickButton,
    currentWidth,
  } = props;
  const [open, setOpen] = state;

  const classes = useStyles();

  const handleClose = (func: () => void) => {
    setOpen(false);
    func();
  };

  const clickButton = () => {
    handleClose(handleClickButton);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="confirm-dialog-slide-title"
      aria-describedby="confirm-dialog-slide-description"
    >
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={12} md={9}>
          <DialogTitle id="confirm-dialog-slide-title">{title}</DialogTitle>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <DialogContent id="confirm-dialog-slide-description">
              <DialogContentText
                className={classes.textColorPrimary}
                style={{ textAlign: "justify" }}
              >
                {message}
              </DialogContentText>
            </DialogContent>
          </div>
          {hasButton && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                paddingLeft: "10px",
              }}
            >
              <DialogActions id="question-dialog-actions">
                <Button
                  variant="contained"
                  onClick={clickButton}
                  className={classes.buttonDialog}
                  style={{ width: currentWidth }}
                >
                  {labelButton}
                </Button>
              </DialogActions>
            </div>
          )}
        </Grid>
        <Hidden xsDown>
          <Grid item md={3}>
            <div style={{ padding: "5px" }}>
              <div id="confirm-dialog-image">
                <img src={img} alt="imagem de escritorio"></img>
              </div>
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </Dialog>
  );
}

export default ConfirmDialog;
