import { Box, createStyles, Divider, List, ListItem, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";
import { PageContent } from "@portal-do-titular/ui-components";
import { handleHashOnPathname, scrollToRef, scrollToRefByWrapperId } from "@portal-do-titular/utils";
import React, { useEffect, useRef } from "react";
import ColumnsLayout from "../layout/ColumnsLayout";
import "./cookies-policy.css";
import { DataSection, generateCookiesPolicySections } from "./data-cookies-policy";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textColor: {
      color: theme.palette.secondary.contrastText,
    },
  })
);

function CookiesPolicySumary(props: {
  sections: DataSection[];
  goTo: (d: DataSection) => void;
}) {
  const classes = useStyles();
  return (
    <List component="nav" >
      {
        props.sections.map((item) => (
          <ListItem
            button
            dense
            onClick={() => props.goTo(item)}
            key={`s-${item.id}-${Date.now()}`}
          >
            <ListItemText
              primary={(
                <Typography
                  className={classes.textColor}
                >
                  {item.label}
                </Typography>
              )}
            />
          </ListItem>
        ))
      }
    </List>
  );
}

function CookiesPolicyData(props: {
  sections: DataSection[];
}) {
  const title = "Política de uso de cookies";
  return (
    <PageContent>
      <Box pb={5} >
        <Typography variant="h3" >
          {title}
        </Typography>
      </Box>
      {
        props.sections.map((item) => {
          return (
            <div
              ref={item.ref}
              id={item.id}
              key={`d-${item.id}-${Date.now()}`}
            >
              <Box>
                <Typography variant="h5" >
                  {item.label}
                </Typography>
                <Divider />
                {item.content()}
              </Box>
            </div>
          )
        })
      }
    </PageContent>
  );
}

function CookiesPolicy() {
  const sections = generateCookiesPolicySections();
  const wrapperId = "wrapper-cookies-policy-data";
  const handleSection = (section: DataSection) => {
    console.log(section);
    handleHashOnPathname(section.id);
    scrollToRefByWrapperId(section.ref, wrapperId);
  };
  useEffect(() => {
    const hash = document.location.hash || "";
    console.log("hash: " + hash)
    if (!hash) {
      return;
    }
    const cleanHash = hash.replace(/^#/, "");
    const selected = sections.find((s) => s.id === cleanHash);
    if (selected) {
      handleSection(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Sumary = () => CookiesPolicySumary({ sections, goTo: handleSection });
  return (
    <ColumnsLayout
      data={Sumary}
      contentId={wrapperId}
    >
      <CookiesPolicyData sections={sections} />
    </ColumnsLayout>
  );
}

export default CookiesPolicy;
