import { Box, Container } from "@material-ui/core";
import { Form } from "@unform/web";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import ReactInputVerificationCode from "react-input-verification-code";

import "./CodeForm.css";

export interface CodeFormProps {
  onSubmit: (param: string) => void;
}

const CodeForm = ({ onSubmit }: CodeFormProps) => {
  const [state, setState] = useState("");

  useEffect(() => {
    if (state.length === 4) {
      onSubmit(state);
      setState("");
    }
  }, [state, onSubmit]);

  return (
    <Box display="flex" justifyContent="center" className="code-form">
      <ReactInputVerificationCode
        onChange={setState}
        placeholder=""
        value={state}
      />
    </Box>
  );
};

export default CodeForm;
