import {
  Grid,
  InputAdornment,
  Card,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import React from "react";

import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import CustomTextField from "../../custom-inputs/CustomTextField";
import CustomRadioButton from "../../custom-inputs/CustomRadio";
import TextPhoneMask from "../../masks/text-phone-mask/TextPhoneMask";
import { Form } from "@unform/web";

import "./ConfirmAuthForm.css";

export interface ConfirmAuthDTO {
  value: string;
  type: string;
  personId: number;
}

export interface ConfirmAuthFormProps {
  description?: string;
  email: string;
  telefone: string;
  personId: number;
  onSubmit: (data: ConfirmAuthDTO) => void;
  button?: {
    label: string;
  };
}

export function ConfirmAuthForm(props: ConfirmAuthFormProps) {
  const { description, onSubmit, button, email, telefone, personId } = props;

  const [selectedValue, setSelectedValue] = React.useState("email");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleSubmit = (param) => {
    onSubmit({
      value: param[selectedValue],
      type: selectedValue.toUpperCase(),
      personId: personId,
    });
  };

  return (
    <Card id="confirm-auth-root">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexDirection="column"
        p={4}
      >
        <Box>
          <Typography className="confirm-code-text" variant="h5" paragraph>
            Tipo de contato
          </Typography>
        </Box>
        <Typography id="confirm-auth-description ">{description}</Typography>
        <Box>
          <Form onSubmit={handleSubmit} id="confirm-auth-form">
            <Box display="flex">
              <CustomTextField
                margin="dense"
                fullWidth
                required
                disabled
                name="email"
                variant="outlined"
                placeholder="E-mail"
                defaultValue={email}
                InputProps={{
                  type: "email",
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <CustomRadioButton
                checked={selectedValue === "email"}
                onChange={handleChange}
                value="email"
                name="radio-button"
              />
            </Box>
            <Box display="flex">
              <CustomTextField
                margin="dense"
                fullWidth
                required
                disabled
                name="phone"
                variant="outlined"
                placeholder="Telefone"
                defaultValue={telefone}
                InputProps={{
                  type: "tel",
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                  inputComponent: TextPhoneMask,
                }}
              />
              <CustomRadioButton
                checked={selectedValue === "phone"}
                onChange={handleChange}
                value="phone"
                name="radio-button"
              />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              {button && (
                <Button
                  type="submit"
                  id="one-column-button"
                  variant="contained"
                  color="primary"
                >
                  {button.label}
                </Button>
              )}
            </Box>
          </Form>
        </Box>
      </Box>
    </Card>
  );
}

export default ConfirmAuthForm;
