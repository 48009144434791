import {
  InputAdornment,
  Container,
  Box,
  Typography,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import "../menu-column-form/MenuColumnForm.css";
import MenuColumnForm from "../menu-column-form/MenuColumnForm";
import CustomTextField from "../../custom-inputs/CustomTextField";
import RequestForm from "../menu-column-form/RequestForm";
import { AuthContext, DomainContext, useApi } from "@syonet/gpd-hooks";
import ConfirmDialog from "../../modals/ConfirmDialog";
import { useHistory } from "react-router-dom";
import WarningDialog from "../../modals/WarningDialog";
import classes from "*.module.css";

export interface RequestDescriptionProps {
  onSubmit: (param: EventCrm) => void;
  setSubject: string;
  viewMenus: () => void;
}

export interface EventCrm {
  subject: string;
  observation: string;
}

export interface RequestDescriptionDTO {
  label: string;
}

const useStyles = makeStyles({
  colorPrimary: {
    color: "#1e1e1e",
  },
});

export function RequestDescription({
  onSubmit,
  setSubject,
  viewMenus,
}: RequestDescriptionProps) {
  const { api } = useApi();
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openFailDialog, setOpenFailDialog] = useState(false);
  const [observationText, setObservation] = useState("");
  const { token } = useContext(AuthContext);
  const { push } = useHistory();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const { colorPrimary } = useStyles();

  const handlEventSubmit = (param: EventCrm) => {
    api
      .post("/v1/protocol", param, config)
      .then(openOkDialog)
      .catch((error) => {
        openErrorDialog();
        console.error(Error(error?.response?.data?.message));
      });
  };

  const handleDialogClose = (value) => {
    setOpenSuccessDialog(value);
    setObservation("");
    viewMenus();
  };

  const handleDialogError = (value) => {
    setOpenFailDialog(value);
    setObservation("");
    viewMenus();
  };

  const cleanField = () => {
    handleDialogClose(false);
    handleDialogError(false);
    setObservation("");
    viewMenus();
  };

  function openOkDialog() {
    setOpenSuccessDialog(true);
  }

  function openErrorDialog() {
    setOpenFailDialog(true);
  }

  return (
    <RequestForm
      button={{
        label: "enviar solicitações",
        onSubmit: handlEventSubmit,
        isDisabled: observationText === "" || !observationText,
      }}
      button2={{
        label: "voltar ao menu",
        onSubmit: cleanField,
      }}
    >
      <Container>
        <Box margin="5px">
          <CustomTextField
            className={colorPrimary}
            disabled
            value={setSubject}
            placeholder="Tipo da Solicitaçao"
            name="subject"
            variant="outlined"
            fullWidth
            InputProps={{}}
          />
          <CustomTextField
            className={colorPrimary}
            required
            placeholder="Descrição"
            id="observation"
            name="observation"
            variant="outlined"
            value={observationText}
            onChange={(event) => setObservation(event.target.value)}
            multiline
            rows={10}
            fullWidth
            InputProps={{
              style: {
                padding: "30px",
              },
            }}
          />
        </Box>
      </Container>
      <ConfirmDialog
        title="Olá,"
        message="O evento criado com sucesso no Syonet CRM!"
        state={[openSuccessDialog, handleDialogClose]}
      />
      <WarningDialog
        title="Atenção,"
        message="Infelizmente não foi possível criar o evento no Syonet CRM."
        state={[openFailDialog, handleDialogError]}
      />
    </RequestForm>
  );
}

export default RequestDescription;
