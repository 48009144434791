import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmDialogImage: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#CDECFF",
      height: "133px",
      width: "133px",
      borderRadius: "50%",
    },
    buttonDialog: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    textColorPrimary: {
      color: "#1E1E1E",
    },
    confirmDialogSlideTitle: {
      paddingBottom: "0px",
    },

    confirmDialogSlideDescription: {
      paddingTop: "0px",
    },
  })
);

export { useStyles };
