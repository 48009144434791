import React, { useContext, Dispatch, SetStateAction, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
  Slide,
} from "@material-ui/core";
import TextPhoneMask from "../masks/text-phone-mask/TextPhoneMask";
import { TransitionProps } from "@material-ui/core/transitions";
import { AuthContext, useApi, DomainContext, Person } from "@syonet/gpd-hooks";

import { Form } from "@unform/web";
import CustomTextField from "../custom-inputs/CustomTextField";
import { EventCrm } from "../forms/menu-form/RequestDescription";

import { useStyles } from "./GenericProtocolDialog.style";

export interface GenericProtocolProps {
  handleProtocolCreate?: () => void;
  state: [boolean, Dispatch<SetStateAction<boolean>>];
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function GenericProtocolDialog({
  state,
  handleProtocolCreate,
}: GenericProtocolProps) {
  const { person } = useContext(AuthContext);

  const [open, setOpen] = state;
  const classes = useStyles();
  const { api } = useApi();
  const { token } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [observationText, setObservation] = useState("");
  const [phone, setPhone] = useState("");

  const handleClose = (func: () => void) => {
    setOpen(false);
    func();
  };

  const clickButton1 = () => {
    handleClose(() => console.log("Closed Protocol Generic"));
  };

  const save = () => {
    let info =
      "Portal Titular LGPD: Cliente não encontrou cadastro na base" +
      "\nNome: " +
      person?.name +
      "\nEmail: " +
      person?.email +
      "\nTelefone: " +
      phone +
      "\nSolicitação: " +
      observationText;
    handleEventSubmit({
      subject: "Cliente não encontrou cadastro na base",
      observation: info,
    });
  };

  const handleEventSubmit = (param: EventCrm) => {
    api
      .post("/v1/protocol/generic", param, config)
      .then(() => handleClose(handleProtocolCreate))
      .catch((message) => {
        console.error(message);
      });
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <div className={classes.root}>
        <DialogTitle id="form-dialog-title">Nova Solicitação</DialogTitle>
        <DialogContent>
          <Form onSubmit={save}>
            <Grid container>
              <Grid item sm={12} md={12}>
                <Typography variant="h2" className={classes.field}>
                  Nome
                </Typography>
                <CustomTextField
                  fullWidth
                  className={classes.disabled}
                  disabled
                  name="name"
                  variant="outlined"
                  defaultValue={person?.name}
                />
              </Grid>
              <Grid item sm={12} md={7} className={classes.space}>
                <Typography variant="h2" className={classes.field}>
                  E-mail
                </Typography>
                <CustomTextField
                  fullWidth
                  className={classes.disabled}
                  disabled
                  name="email"
                  variant="outlined"
                  defaultValue={person?.email}
                  InputProps={{
                    type: "email",
                  }}
                />
              </Grid>
              <Grid item sm={12} md={5}>
                <Typography variant="h2" className={classes.field}>
                  Telefone (*)
                </Typography>
                <CustomTextField
                  fullWidth
                  required
                  name="phone"
                  variant="outlined"
                  placeholder="Telefone"
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  InputProps={{
                    type: "tel",
                    inputComponent: TextPhoneMask,
                  }}
                />
              </Grid>
              <Grid item sm={12} md={12}>
                <Typography variant="h2" className={classes.field}>
                  Descrição (*)
                </Typography>
                <CustomTextField
                  required
                  fullWidth
                  placeholder="Descrição"
                  id="observation"
                  name="observation"
                  variant="outlined"
                  value={observationText}
                  onChange={(event) => setObservation(event.target.value)}
                  multiline
                  rows={5}
                  InputProps={{
                    style: {
                      padding: "30px",
                    },
                  }}
                />
                <Typography variant="h2" className={classes.field}>
                  (*) Campos obrigatório
                </Typography>
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions id="question-dialog-actions">
          <Button variant="contained" onClick={clickButton1} color="secondary">
            Cancelar
          </Button>
          <Button
            variant="contained"
            type="submit"
            onClick={save}
            color="primary"
            disabled={!observationText || !phone}
          >
            Salvar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default GenericProtocolDialog;
