import {
  InputAdornment,
  Container,
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import OneColumnForm from "../../forms/one-column-form/OneColumnForm";

import "./LoginForm.css";

import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import CustomTextField from "../../custom-inputs/CustomTextField";
import { DomainContext } from "@syonet/gpd-hooks";
import { useLocation, useRouteMatch, Link } from "react-router-dom";
import ConfirmDialog from "../../modals/ConfirmDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      maxWidth: "400px",
    },
    colorPrimary: {
      color: "#1e1e1e",
    },
  })
);

export interface LoginFormDTO {
  email: string;
  password: string;
}

export interface LoginFormProps {
  links: { link1: string; link2: string };
  onSubmit: (param: LoginFormDTO) => void;
}

export function LoginForm({
  onSubmit,
  links: { link1, link2 },
}: LoginFormProps) {
  const { state } = useLocation<{ email: string }>();

  const classes = useStyles();

  const [openExistPersonDialog, setOpenExistPersonDialog] = useState(false);
  const { path } = useRouteMatch();
  const { slug, injectSlug } = useContext(DomainContext);

  useEffect(() => {
    const email = state?.email;
    if (!email) return;
    setOpenExistPersonDialog(true);
  }, [state]);

  const submitHandler = (data: LoginFormDTO) => {
    onSubmit(data);
  };

  const handleExistPersonDialogClose = (value) => {
    setOpenExistPersonDialog(value);
  };

  return (
    <>
      <ConfirmDialog
        title="Olá,"
        message="Usuario ja cadastrado, tente realizar o login ou recupere sua senha."
        state={[openExistPersonDialog, handleExistPersonDialogClose]}
      />
      <OneColumnForm button={{ label: "Entrar", onSubmit: submitHandler }}>
        <CustomTextField
          fullWidth
          className={classes.input}
          required
          name="email"
          variant="outlined"
          placeholder="E-mail"
          defaultValue={state?.email}
          InputProps={{
            type: "email",
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <CustomTextField
          fullWidth
          className={classes.input}
          required
          name="password"
          variant="outlined"
          placeholder="Senha"
          InputProps={{
            type: "password",
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box>
          <Box p={1} textAlign="justify">
            <Typography variant="caption" className={classes.colorPrimary}>
              Primeiro acesso? Precisamos que realize o cadastro,
              {" "}<a href={`${slug}/cadastro`} >clique aqui</a>
              {" "}para dar início ao processo.
            </Typography>
          </Box>
          <Box p={1} textAlign="justify">
            <Typography variant="caption" className={classes.colorPrimary}>
              Esqueceu sua senha?{" "}
              <Link to={`${injectSlug(path)}/encontrar-conta`}>
                Clique aqui
              </Link>
            </Typography>
          </Box>
        </Box>
      </OneColumnForm>
    </>
  );
}

export default LoginForm;
