import React, { ReactNode } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import "./OneColumnForm.css";

import { Button, Typography } from "@material-ui/core";
import { Form } from "@unform/web";
import { FullscreenExitTwoTone } from "@material-ui/icons";

/* eslint-disable-next-line */
export interface OneColumnFormProps {
  title?: string;
  button?: {
    onSubmit: (data: any) => void;
    label: string;
  };
  formRef?;
  minWidth?: number;
  minHeight?: number;
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "418px",
      [theme.breakpoints.down("md")]: {
        maxWidth: "82vw",
      },
      padding: "13px 50px 32px 50px",
    },
  })
);

export function OneColumnForm(props: OneColumnFormProps) {
  const { title, children, button, formRef, minWidth, minHeight } = props;
  const classes = useStyles();
  const classCenter = "one-column-class-center";

  return (
    <Card
      className={classes.root}
      style={{ minWidth: minWidth, minHeight: minHeight }}
    >
      <div id="one-column-icon" className={classCenter}>
        <AccountCircleIcon color="secondary" style={{ fontSize: 58 }} />
      </div>
      <div id="one-column-title" className={classCenter}>
        {title}
      </div>
      <Form ref={formRef} onSubmit={button.onSubmit} className={classCenter}>
        {children}
        {button && (
          <Button
            type="submit"
            id="one-column-button"
            variant="contained"
            color="primary"
          >
            {button.label}
          </Button>
        )}
      </Form>
    </Card>
  );
}

export default OneColumnForm;
