import React from "react";

import { Route, Link } from "react-router-dom";

import {
  QuestionDialog,
  InfoCard,
  KnowMore,
  ConfirmDialog,
  CreateAccountForm,
  EmailAndPhoneForm,
  CPFForm,
  LoginForm,
  MessageBlock,
  AuthTitle,
  CreateYourLogin,
  ConfirmAuthForm,
  SimpleStepsForm,
  StepOneFrom,
  StepTwoFrom,
  StepTreeFrom,
  StepFourFrom,
  ContactPreferenceForm,
} from "@portal-do-titular/ui-components";

import { Button, Grid, Hidden, TextField } from "@material-ui/core";

import consentimentoImage from "../../assets/consentimento.svg";
import direitosImage from "../../assets/direitos.svg";
import dadosImage from "../../assets/dados.svg";
import peopleAuth from "../../assets/peopleAuth.svg";
import { Form } from "@unform/web";

export function Showroom() {
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Route
        path="/showcase"
        exact
        render={() => (
          <div>
            <ul>
              <li>
                <Link to="/showcase/page-2">
                  Click here for QuestionDialog.
                </Link>
              </li>
              <li>
                <Link to="/showcase/page-3">Click here for InfoCard.</Link>
              </li>
              <li>
                <Link to="/showcase/page-4">Click here for KnowMore.</Link>
              </li>
              <li>
                <Link to="/showcase/page-5">Click here for OneColumnForm.</Link>
              </li>
              <li>
                <Link to="/showcase/page-6">Click here for ConfirmDialog.</Link>
              </li>
              <li>
                <Link to="/showcase/page-7">Click here for WelcomeBlock.</Link>
              </li>
              <li>
                <Link to="/showcase/page-8">Click here for Auth Title.</Link>
              </li>
              <li>
                <Link to="/showcase/page-9">
                  Click here for Simple Step Form.
                </Link>
              </li>
              <li>
                <Link to="/showcase/page-10">
                  Click here for Create You Login.
                </Link>
              </li>
              <li>
                <Link to="/showcase/page-11">Click here for confirm auth.</Link>
              </li>
              <li>
                <Link to="/showcase/page-12">Click here for Step One From</Link>
              </li>
              <li>
                <Link to="/showcase/page-13">Click here for Step Two From</Link>
              </li>
              <li>
                <Link to="/showcase/page-14">
                  Click here for Step Four From
                </Link>
              </li>
              <li>
                <Link to="/showcase/page-15">
                  Click here for Contact Preference Form
                </Link>
              </li>
            </ul>
          </div>
        )}
      />
      <Route
        path="/showcase/page-2"
        exact
        render={() => (
          <div>
            <QuestionDialog
              title="Olá,"
              message="Seus dados tem incoscistências, gostaria de entar pelo CPF ou tentar com o suporte técnico?"
              labelButton1="Tentar por cpf"
              labelButton2="Abrir solicitação"
              handleClickButton1={() => console.log("click button 1")}
              handleClickButton2={() => console.log("click button 2")}
              state={[open, setOpen]}
            />

            <Button variant="text" onClick={() => setOpen(true)}>
              Open
            </Button>
          </div>
        )}
      />
      <Route
        path="/showcase/page-3"
        exact
        render={() => (
          <Grid container justify="space-evenly">
            <Grid item xs={12} sm={6} md={4}>
              <InfoCard
                title="CONSENTIMENTO"
                description="O seu consentimento é a base para que dados pessoais possam ser tratados."
                image={consentimentoImage}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InfoCard
                title="SEUS DIREITOS"
                description="A Lei Geral de Proteção de garante diferentes direitos a você em relação aos seus dados, como: eliminação, correção, etc."
                image={direitosImage}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <InfoCard
                title="SEUS DADOS"
                description="Os dados ( e-mails, telefone, cpf, endereço, etc) são seus, logo os direitos têm que ser a seu o prol"
                image={dadosImage}
              />
            </Grid>
          </Grid>
        )}
      />
      <Route
        path="/showcase/page-4"
        exact
        render={() => (
          <div>
            <div>
              <KnowMore />
            </div>
          </div>
        )}
      />
      <Route
        path="/showcase/page-5"
        exact
        render={() => (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
              <CreateAccountForm onSubmit={(param) => console.log(param)} />
            </div>
            <div>
              <EmailAndPhoneForm onSubmit={(param) => console.log(param)} />
            </div>
            <div>
              <CPFForm onSubmit={(param) => console.log(param)} />
            </div>
            <div>
              <LoginForm
                links={{
                  link1: `#`,
                  link2: `#`,
                }}
                onSubmit={(param) => console.log(param)}
              />
            </div>
          </div>
        )}
      />
      <Route
        path="/showcase/page-6"
        exact
        render={() => (
          <div>
            <ConfirmDialog
              title="Olá,"
              message="Obrigada pelo acesso! Neste portal estão disponibilizados os dados que a concessionária (nome da concessionária tem disponível em em sua base de dados."
              state={[open, setOpen]}
            />
            <Button variant="text" onClick={() => setOpen(true)}>
              Open
            </Button>
          </div>
        )}
      />
      <Route
        path="/showcase/page-7"
        exact
        render={() => (
          <div>
            <Hidden smDown>
              <MessageBlock
                title="Bem-Vindo (a)!"
                message="É um prazer em receber você no nosso portal. Estamos muito felizes em proteger seus dados juntamente com você."
              />
            </Hidden>
          </div>
        )}
      />
      <Route
        path="/showcase/page-8"
        exact
        render={() => (
          <div>
            <AuthTitle
              title="PORTAL DO TITULAR"
              description="Para buscar os seu dados em nossa base, será necessario escolher e preencher os campos vazios abaixo."
              image={peopleAuth}
            />
          </div>
        )}
      />
      <Route
        path="/showcase/page-9"
        exact
        render={() => (
          <SimpleStepsForm
            button={{ label: "CONTINUAR", onSubmit: (p) => console.table(p) }}
            step={1}
            description="Uma demonstração do componente"
          >
            <TextField></TextField>
          </SimpleStepsForm>
        )}
      />
      <Route
        path="/showcase/page-10"
        exact
        render={() => <CreateYourLogin onSubmit={(p) => console.table(p)} />}
      />
      <Route
        path="/showcase/page-11"
        exact
        render={() => (
          <ConfirmAuthForm
            onSubmit={(param) => console.log(param)}
            button={{ label: "Continuar" }}
            email="betina.somer@gmail.com"
            personId={123}
            telefone="51997251486"
            description="Olá, escolha SMS ou E-mail para rececer nossa confirmação  de acesso ao cadastro. Iremos enviar um código que servirá de confirmação para acessar seu cadastro."
          />
        )}
      />
      <Route
        path="/showcase/page-12"
        exact
        render={() => (
          <StepOneFrom onSubmit={(p) => console.table(p)} step={1} />
        )}
      />
      <Route
        path="/showcase/page-13"
        exact
        render={() => (
          <StepTwoFrom step={2} onSubmit={(p) => console.table(p)} />
        )}
      />
      <Route
        path="/showcase/page-14"
        exact
        render={() => (
          <StepTreeFrom step={3} onSubmit={(p) => console.table(p)} />
        )}
      />
      <Route
        path="/showcase/page-14"
        exact
        render={() => (
          <StepFourFrom step={3} onSubmit={(p) => console.table(p)} />
        )}
      />
      <Route
        path="/showcase/page-15"
        exact
        render={() => (
          <ContactPreferenceForm onClose={() => console.log("close")} />
        )}
      />
    </div>
  );
}

export const showroomLink = () => (
  <ul>
    <li>
      <Link to="/showcase">Click here for Showroom.</Link>
    </li>
  </ul>
);

export default Showroom;
