import React, { useCallback, useContext } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { AuthContext, DomainContext } from "@syonet/gpd-hooks";
import { useHistory } from "react-router-dom";
import { useMenuDrawerStyles } from "./MenuDrawer.style";
import MenuSectionLogo from "../menu-section-logo/MenuSectionLogo";

export interface Props {
  open: boolean;
  onClose: () => void;
  onKnowMore?: () => void;
  onHome?: () => void;
  isLogout?: boolean;
}

export function MenuDrawer(props: Props) {
  const {
    button,
    buttonsContainer,
    closeContainer,
    container,
    text,
    textButton,
    textColor,
  } = useMenuDrawerStyles(props);
  const { onClose, onKnowMore, onHome, isLogout } = props;
  const { signed, signedAndActive } = useContext(AuthContext);
  const { push } = useHistory();
  const { slug } = useContext(DomainContext);
  const { logout } = useContext(AuthContext);
  const handleHome = () => {
    onClose();
    if (onHome !== undefined) {
      onHome();
    } else {
      push(`/${slug}`);
    }
  };
  const handleKnowMore = () => {
    onClose();
    if (onKnowMore !== undefined) {
      onKnowMore();
    } else {
      push(`/${slug}#saiba-mais`);
    }
  };
  const handleLogin = () => {
    if (signedAndActive) {
      push(`/${slug}/perfil`);
    } else if (signed) {
      push(`/${slug}/autenticacao-dupla`);
    } else {
      push(`/${slug}/login`);
    }
    onClose();
  };
  const handleLogout = () => logout();
  return (
    <Box className={container}>
      <Box className={closeContainer}>
        <MenuSectionLogo
          boxProps={{
            color: textColor,
            className: "",
          }}
        />
        <Button onClick={() => onClose()}>
          <Close />
        </Button>
      </Box>
      <Box className={buttonsContainer}>
        <Button onClick={handleHome}>
          <Typography className={text}>Home</Typography>
        </Button>
        <Button onClick={handleKnowMore}>
          <Typography className={text}>Saiba mais</Typography>
        </Button>
        {!isLogout &&
          <Button
            className={button}
            variant="outlined"
            color="primary"
            onClick={() => handleLogin()}
          >
            <Typography className={textButton} color="primary">
              {signedAndActive ? "Perfil" : "Sou Cadastrado"}
            </Typography>
          </Button>
        }
        {isLogout &&
          <Button
            className={button}
            variant="outlined"
            color="primary"
            onClick={handleLogout}
          >
            <Typography className={textButton} color="primary">
              Sair
            </Typography>
          </Button>
        }
      </Box>
    </Box>
  );
}

export default MenuDrawer;
