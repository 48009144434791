import { Box, Switch } from "@material-ui/core";
import {
  ContactPreferencesData,
  GroupByProcess,
  MenusContext,
  PreferencesData,
  useConsent
} from "@syonet/gpd-hooks";
import React, { useContext, useEffect, useState } from "react";
import { MenuTitle } from "../../title/menu-title/MenuTitle";
import FormActions from "../formActions/FormActions";
import "./ConsentManagerForm.css";


interface IProps {
  onCancel: () => void;
  onSave: () => void;
}

export function ConsentManagerForm(props: IProps) {
  const { finalities, preferences } = useContext(MenusContext);

  const { sendConsent } = useConsent();
  const [groupedFinalities, setGroupedFinalities] = useState<GroupByProcess[]>(
    null
  );
  const [currentPreferences, setCurrentPreferences] = useState<
    ContactPreferencesData
  >();

  const handleBack = () => {
    props.onCancel();
  };

  useEffect(() => {
    setGroupedFinalities(finalities);
    setCurrentPreferences({ preferences });
  }, [finalities, preferences]);

  const handleSave = () => {
    const crmId = currentPreferences.preferences.map(({ crmID }) => crmID)[0];
    sendConsent(crmId, currentPreferences);
    props.onSave();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    const id = Number(name.split("|")[0]);
    const processID = Number(name.split("|")[1]);

    const newGroups = groupedFinalities;

    const currentGroupIndex = groupedFinalities.findIndex(
      (group) => group.processID === processID
    );

    const currentFinalityIndex = groupedFinalities[
      currentGroupIndex
    ].finalities.findIndex((finality) => finality.finalityID === id);

    newGroups[currentGroupIndex].finalities[
      currentFinalityIndex
    ].contactPreference.consent = checked;

    setGroupedFinalities(newGroups);

    const newPreferences: PreferencesData[] = currentPreferences.preferences.map(
      ({
        finalityID,
        crmID,
        description,
        preferenceEmail,
        preferenceSms,
        preferenceTelephone,
        preferenceWhatsapp,
        consent,
      }) => {
        if (finalityID === id) {
          return {
            finalityID,
            crmID,
            description,
            preferenceEmail,
            preferenceSms,
            preferenceTelephone,
            preferenceWhatsapp,
            consent: checked,
          };
        }
        return {
          finalityID,
          crmID,
          description,
          preferenceEmail,
          preferenceSms,
          preferenceTelephone,
          preferenceWhatsapp,
          consent,
        };
      }
    );

    setCurrentPreferences({ preferences: newPreferences });
  };

  return (
    <>
      <Box className="consent-container">
        <MenuTitle title="Gerenciador de Consentimentos" />
        <Box className="menu-main-container">
          {!!groupedFinalities &&
            groupedFinalities
              .sort((a, b) => a.processName.localeCompare(b.processName))
              .map(({ processName, processID, finalities }) => {
                return (
                  <Box key={processID}>
                    <h2 className="process-titles">{processName}</h2>
                    {finalities
                      .sort((a, b) => a.finalityID - b.finalityID)
                      .map(({ contactPreference, description, finalityID }) => {
                        return (
                          <Box key={finalityID} className="finality-container">
                            <Box className="text-finality">
                              <span className="finality-description">
                                {description}
                              </span>
                            </Box>
                            <Switch
                              name={`${finalityID}|${processID}`}
                              checked={contactPreference.consent}
                              color="primary"
                              onChange={handleChange}
                            />
                          </Box>
                        );
                      })}
                  </Box>
                );
              })}
        </Box>
      </Box>
      <FormActions confirm={handleSave} cancel={handleBack} />
    </>
  );
}
