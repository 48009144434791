import { Box, InputAdornment } from "@material-ui/core";
import React from "react";
import OneColumnForm from "../../forms/one-column-form/OneColumnForm";
import TextPhoneMask from "../../masks/text-phone-mask/TextPhoneMask";

import "./EmailAndPhoneForm.css";

import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import CustomTextField from "../../custom-inputs/CustomTextField";

export interface EmailAndPhoneFormDTO {
  email: string;
  phone: string;
}

export interface EmailAndPhoneFormProps {
  onSubmit: (params: EmailAndPhoneFormDTO) => void;
  inputRefEmail?: React.Ref<unknown>;
  inputRefPhone?: React.Ref<unknown>;
  minHeight?: number;
  minWidth?: number;
}

export function EmailAndPhoneForm({
  onSubmit,
  inputRefEmail,
  inputRefPhone,
  minHeight,
  minWidth,
}: EmailAndPhoneFormProps) {
  const submitHandler = (data: EmailAndPhoneFormDTO) => {
    onSubmit(data);
  };

  return (
    <OneColumnForm
      button={{ label: "Continuar", onSubmit: submitHandler }}
      title="Telefone e E-mail"
      minHeight={minHeight}
      minWidth={minWidth}
    >
      <Box className="text-input-conteiner">
        <CustomTextField
          fullWidth
          margin="dense"
          required
          name="email"
          variant="outlined"
          placeholder="E-mail"
          InputProps={{
            type: "email",
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <CustomTextField
          fullWidth
          margin="dense"
          required
          name="phone"
          variant="outlined"
          placeholder="Telefone"
          InputProps={{
            type: "tel",
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>
            ),
            inputComponent: TextPhoneMask,
          }}
        />
      </Box>
    </OneColumnForm>
  );
}

export default EmailAndPhoneForm;
