import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useCopyrightStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "52px",
      display: "flex",
      alignItems: "center",
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        height: "52px",
        display: "flex",
        justifyContent: "center",
      },
    },
    containerText: {
      maxWidth: theme.breakpoints.values.md,
      width: "170px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    logo: {
      width: "24px",
      height: "24px",
      marginRight: "17px",
    },
    text: {
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "24px",
      letterSpacing: "-0.015em",
    },
    textColor: {
      color: theme.palette.secondary.contrastText,
    },
  })
);

export { useCopyrightStyles };
