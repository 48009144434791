import React, { createContext, useCallback, useContext, useState } from "react";
import jwtDecode from "jwt-decode";

import { Person } from "./usePerson";
import useApi from "./useApi";
import { useHistory } from "react-router-dom";
import { DomainContext } from "./DomainContext";

interface AuthContextData {
  // eslint-disable-next-line @typescript-eslint/ban-types
  person: Person;
  token: string;
  signed: boolean;
  signedAndActive: boolean;
  logout: () => void;
  signIn: (data: SignInData) => Promise<void>;
}

interface SignInData {
  email: string;
  password: string;
}

interface ResponseData {
  token: string;
}

interface DecodeToken {
  person;
  exp;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider = ({ children }) => {
  const [signedPerson, setSignedPerson] = useState<Person>(null);
  const [token, setToken] = useState("");
  const { api, handleRequest } = useApi();
  const { push } = useHistory();
  const { slug } = useContext(DomainContext);

  const clearSession = useCallback(() => {
    setSignedPerson(null);
    setToken("");
    localStorage.removeItem(sessionKey);
  }, []);

  const logout = useCallback(() => {
    clearSession();
    push(`/${slug}/login`);
  }, [slug, push, clearSession]);

  React.useEffect(() => {
    const sessionJson = localStorage.getItem(sessionKey);
    if (!sessionJson) {
      clearSession();
      return;
    }
    const { token, exp, person, slug: oldSlug } = JSON.parse(sessionJson);
    const expired = new Date().getTime() > new Date(exp * 1000).getTime();
    const slugChanged = !!slug && oldSlug !== slug;

    if (slugChanged || expired || !token || !person) {
      clearSession();
      return;
    }

    setToken(token);
    setSignedPerson(person);
  }, [slug, logout, clearSession]);

  async function signIn(credentials: SignInData) {
    const { data } = await handleRequest(() =>
      api.post<ResponseData>("v1/auth/login", credentials)
    );
    const { token } = data;
    const { person, exp } = jwtDecode<DecodeToken>(token);
    const personObj = JSON.parse(person);

    localStorage.setItem(
      sessionKey,
      JSON.stringify({ token, exp, person: personObj, slug })
    );

    setSignedPerson({ ...personObj });
    setToken(token);
    if (personObj?.isActive) {
      push(`/${slug}/perfil`);
    } else {
      push(`/${slug}/autenticacao-dupla`);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!signedPerson,
        signedAndActive: signedPerson?.isActive,
        signIn,
        logout,
        person: signedPerson,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const sessionKey = "session";
