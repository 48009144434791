import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageBlockContent: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    title: {
      [theme.breakpoints.down("lg")]: {
        fontSize: "2.25rem",
      },
    },
    text: {
      color: theme.palette.secondary.contrastText,
    },
    main: {
      width: "100%",
      height: "100%",
      borderRadius: "0px 6% 6% 0px",
      padding: "20px 20px 0 80px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      gridTemplateRows: "auto 90% auto",
      zIndex: 1,
      backgroundColor: theme.palette.secondary.main,
    },
    header: {
      display: "flex",
      alignItems: "center",
    },
    copyright: {
      display: "flex",
      alignItems: "center",
      alignContent: "space-evenly",
    },
    textColor: {
      color: theme.palette.secondary.contrastText,
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px !important",
        marginTop: "30px",
      },
    },
    /*@media screen and (max-width: 1280px) {
      #message-block-content {
        padding-left: 50px,
      }
    }*/
  })
);

export { useStyles };
