import { InputAdornment, Radio } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomTextField from "../../../custom-inputs/CustomTextField";
import CustomRadioButton from "../../../custom-inputs/CustomRadio";
import TextPhoneMask from "../../../masks/text-phone-mask/TextPhoneMask";
import SimpleStepsForm from "../../simple-steps-form/SimpleStepsForm";
import { Person, useHiddenMask } from "@syonet/gpd-hooks";

import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";

import { useLocation } from "react-router-dom";

import "./StepTwoFrom.css";
import { string } from "yup/lib/locale";

/* eslint-disable-next-line */
export interface StepTwoFromProps {
  step: number;
  onSubmit: (params: sendDTO) => void;
}

export interface sendDTO {
  value: string;
  type: string;
  personId: number;
}

export interface IState {
  telephone: string;
  email: string;
  person: Person;
}

export function StepTwoFrom({ onSubmit, step }: StepTwoFromProps) {
  const { state } = useLocation<IState>();

  const submitHandler = (data: sendDTO) => {
    onSubmit({
      value: data[selectedValue],
      type: selectedValue.toUpperCase(),
      personId: state.person?.id,
    });
  };

  const [selectedValue, setSelectedValue] = React.useState("email");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const hasPerson = !!state?.person;
  const hasPhone = !!state?.telephone;
  const hasEmail = !!state?.email;
  const placeholderPhone = hasPerson && !hasPhone ? "Telefone indisponível" : "Telefone";
  const placeholderEmail = hasPerson && !hasEmail ? "E-mail indisponível" : "E-mail";
  return (
    <SimpleStepsForm
      description="Como você deseja receber o código para redefinir sua senha?"
      button={{ label: "CONTINUE", onSubmit: submitHandler }}
      step={step}
    >
      <div id="step-two-form">
        <CustomTextField
          disabled
          className="step-two-field"
          name="email"
          value={state?.email}
          variant="outlined"
          placeholder={placeholderEmail}
          InputProps={{
            type: "email",
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <CustomRadioButton
          checked={selectedValue === "email"}
          onChange={handleChange}
          value="email"
          color="secondary"
          name="radio-button-email"
        />
      </div>
      <div id="step-two-form">
        <CustomTextField
          disabled
          className="step-two-field"
          name="phone"
          variant="outlined"
          placeholder={placeholderPhone}
          value={state?.telephone}
          InputProps={{
            type: "tel",
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>
            ),
            inputComponent: TextPhoneMask,
          }}
        />
        <CustomRadioButton
          checked={selectedValue === "phone"}
          onChange={handleChange}
          value="phone"
          color="secondary"
          name="radio-button-telefone"
        />
      </div>
    </SimpleStepsForm>
  );
}

export default StepTwoFrom;
