import React, { useContext, useEffect, useState } from "react";

import "./App.css";

import Routes from "./Routes";

import {
  AuthProvider,
  DomainContext,
  MenusProvider,
  useApi,
} from "@syonet/gpd-hooks";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import theme, { ICompanyColors } from "../theme";

export function App() {
  const { push } = useHistory();
  const { setAccount, account } = useContext(DomainContext);
  const { slug } = useParams<{ slug: string }>();
  const [colors, setColors] = useState<ICompanyColors>({
    primary: "#EDD16B",
    secondary: "#1E1E1E",
  });

  const { api, handleRequest } = useApi();

  useEffect(() => {
    handleRequest(() => api.get<Account>(`v1/account?slug=${slug}`))
      .then(({ data }) => {
        if (!data[0] || !data[0].status) {
          push(`/PageNotFound`);
          return;
        } else {
          setAccount(data[0]);
        }
      })
      .catch(() => {
        console.error("Conection failed with Api");
        push(`/PageNotFound`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account?.primaryColor || account?.secondaryColor)
      setColors({
        ...colors,
        primary: account.primaryColor ? account.primaryColor : "#EDD16B",
        secondary: account.secondaryColor ? account.secondaryColor : "#1E1E1E",
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme(colors)}>
        <MenusProvider>
          <Routes />
        </MenusProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
