import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightContainer: {
      [theme.breakpoints.up("md")]: {
        paddingRight: "168.5px",
      },
    },
    card: {
      padding: "30px 30px",
      [theme.breakpoints.down("md")]: {
        padding: "10px 10px",
      },
    },
    titleConteiner: {
      paddingTop: "32px",
      [theme.breakpoints.down("md")]: {
        paddingTop: "80px",
      },
    },
  })
);

export { useStyles };
