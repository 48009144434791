import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import './InfoCard.css'

import { Grid, Typography, Box } from '@material-ui/core';

export interface InfoCardProps {
  title: string;
  description: string;
  image: string;
  maxHeight?: number;
  maxWidth?: number;
}

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
});

export function InfoCard(props: InfoCardProps) {
  const { title, description, image } = props;
  const classes = useStyles();

  return (
    <div style={{
      maxHeight: 400,
      maxWidth: 337,
      marginTop: 50
    }}>
      <Grid container direction="column" alignItems="center" >
        <Grid item>
          <div id="info-card-image">
            <img src={image} alt="consentimento" />
          </div>
        </Grid>
        <Grid item >
          <Box >
            <h1 id="info-card-title">
              {title}
            </h1>
          </Box >
        </Grid>
        <Grid item >
          <p id="info-card-description" >
            {description}
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

export default InfoCard;
