import React, { ReactNode, useContext, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import "./MenuColumnForm.css";
import { Form } from "@unform/web";
import { Box, Button, Grid, Typography } from "@material-ui/core";

/* eslint-disable-next-line */
export interface RequestFormProps {
  title?: string;
  button?: {
    isDisabled: boolean;
    onSubmit: (data: any) => void;
    label: string;
  };
  children?: ReactNode;
  button2?: {
    onSubmit: (data: any) => void;
    label: string;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
  })
);

export function RequestForm(props: RequestFormProps) {
  const { title, children, button, button2 } = props;
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Form onSubmit={button.onSubmit}>
        {children}
        <Grid
          direction="row"
          container
          justify="space-evenly"
          alignItems="center"
        >
          {button2 && (
            <Grid item>
              <Button
                id="menu-protocol-cancel"
                variant="contained"
                color="secondary"
                onClick={button2.onSubmit}
              >
                <Typography
                  color="textSecondary"
                  style={{ fontSize: "10px", fontWeight: 700 }}
                >
                  {button2.label}
                </Typography>
              </Button>
            </Grid>
          )}
          {button && (
            <Grid item>
              <Button
                disabled={button.isDisabled}
                id="menu-protocol-save"
                className="menu-protocol-save-button"
                type="submit"
                variant="contained"
                color="primary"
              >
                <Typography
                  color="textPrimary"
                  style={{ fontSize: "10px", fontWeight: 700 }}
                >
                  {button.label}
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Form>
    </Card>
  );
}

export default RequestForm;
