import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { ReactNode } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "80px 0 0",
      overflowY: "auto",
      overflowX: "hidden",
    },
  })
);

export function PageContent(props: {
  children: ReactNode
}) {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" disableGutters className={classes.root} >
      {props.children}
    </Container>
  );
}

export default PageContent;
